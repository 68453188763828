import { RcFile, message } from '@pankod/refine-antd';

import { initiatingPhase } from './schema';

const validateThumbnailFile = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }

    const isAllowSize = file.size / 1024 / 1024 < initiatingPhase.product.thumbnail.maxSize;
    if (!isAllowSize) {
        message.error(`Image must smaller than ${initiatingPhase.product.thumbnail.maxSize}MB!`);
    }
    return isJpgOrPng && isAllowSize;
};

export { validateThumbnailFile };
