import * as React from 'react';
const Remove = (props) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <circle cx={8} cy={8} r={6.667} fill="#BBBCBF" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.34 10.095a.533.533 0 1 0 .755-.754l-1.34-1.34 1.34-1.342a.533.533 0 1 0-.754-.754L8 7.245l-1.341-1.34a.533.533 0 0 0-.754.754L7.245 8l-1.34 1.34a.533.533 0 0 0 .754.755l1.34-1.34 1.342 1.34Z"
            fill="#fff"
        />
    </svg>
);
export default Remove;
