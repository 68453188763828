import dayjs from 'dayjs';

import { ProductTag, ExpertiseScope, ShowcaseFile } from 'interfaces';
import { mapArrToObject, FILE_TYPE } from 'utils';
import { formatExpertiseScopeFormItem } from '../functions';
import { planningPhase, evaluatingPhase } from 'pages/showcase/schema';

const { timeline } = planningPhase.program;

const formatInitialValue = (data: any) => {
    const result = { ...data };

    if (data?.businessPartner?.industries) {
        result.businessPartner = {
            ...data?.businessPartner,
            industries: (data?.businessPartner?.industries || []).map((item: any) => ({
                ...item,
                value: item.id
            }))
        };
    }

    if (data?.partnership?.servicePackages) {
        result.partnership = {
            ...data?.partnership,
            servicePackages: (data?.partnership?.servicePackages || []).map((item: any) => ({
                ...item,
                value: item.id
            }))
        };
    }

    if (data?.product?.productTags) {
        const appearTags = (data?.product?.productTags || [])
            .filter((item: ProductTag) => item.appearShowcase)
            .map((item: ProductTag) => item.productTagId);

        result.product = {
            ...data?.product,
            productTags: (data?.product?.productTags || []).map((item: ProductTag) => ({
                ...item,
                value: item.productTagId
            })),
            appearTags
        };
    }

    if (data?.expertiseScopes) {
        result.product.expertiseScopes = (data?.expertiseScopes || []).map(
            (item: ExpertiseScope) => item?.expertiseScopeId
        );

        const expertiseDic = mapArrToObject(data?.expertiseScopes, 'expertiseScopeId');
        result.estimation = expertiseDic;

        result.expertiseScopes = formatExpertiseScopeFormItem(data?.expertiseScopes);
    }

    if (data?.files && data?.files?.length) {
        const fileUploads: ShowcaseFile[] = [];
        const thumbnail: ShowcaseFile[] = [];
        const images: ShowcaseFile[] = [];

        data.files.forEach((item: Partial<ShowcaseFile>) => {
            const payload: ShowcaseFile = {
                id: item.id,
                uid: item.id,
                name: item.fileName,
                url: item.path,
                status: 'done'
            };
            switch (item.type) {
                case FILE_TYPE.file:
                    fileUploads.push(payload);
                    break;
                case FILE_TYPE.thumbnail:
                    thumbnail.push(payload);
                    break;
                case FILE_TYPE.image:
                    images.push(payload);
                    break;
                default:
                    fileUploads.push(payload);
                    break;
            }
        });

        result.fileUploads = fileUploads;
        result.thumbnail = thumbnail;
        result.images = images;
    }

    if (data?.program?.deliverables) {
        result.program = {
            ...data?.program,
            deliverables: (data?.program?.deliverables || []).map((item: any) => ({
                ...item,
                value: item.id
            }))
        };
    }

    if (data?.program?.planningStartTime && data?.program?.planningEndTime) {
        result.program = {
            ...result.program,
            [timeline.name]: [
                dayjs(data?.program?.planningStartTime),
                dayjs(data?.program?.planningEndTime)
            ]
        };
    }

    const evaluatingTimeLine = [];
    if (data?.program?.evaluationStartTime) {
        evaluatingTimeLine.push(dayjs(data?.program?.evaluationStartTime));
    }
    if (data?.program?.evaluationEndTime) {
        evaluatingTimeLine.push(dayjs(data?.program?.evaluationEndTime));
    }
    result.program = {
        ...result.program,
        [evaluatingPhase.program.evaluationTimeline.name]: evaluatingTimeLine
    };

    return result;
};

export default formatInitialValue;
