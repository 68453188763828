import { initiatingPhase, planningPhase, deliveryPhase } from 'pages/showcase/schema';
import { useList, useCreate, useOne } from '@pankod/refine-core';
import { useTable } from '@pankod/refine-antd';
import urlcat from 'urlcat';

import { SHOWCASE_ENDPOINTS } from 'configs/api';
import { Showcase } from 'interfaces';
import { INPUT_STATUS_NAME, DEFAULT_PARAMS, STATUS_VALUE, INPUT_STATUS_FIELD_FILTERS } from 'utils';

import resources from 'utils/resources';

const useShowcaseList = () => {
    const { tableProps, filters, setFilters, searchFormProps, tableQueryResult } =
        useTable<Showcase>({
            initialSorter: [
                {
                    field: DEFAULT_PARAMS.sort,
                    order: 'desc'
                }
            ],
            queryOptions: {
                retry: 1
            },
            onSearch: (values: any) => {
                return [
                    {
                        field: 'q',
                        operator: 'eq',
                        value: values.searchValue
                    }
                ];
            }
        });

    const { mutate, isLoading: createLoading } = useCreate();

    const expertiseScopes = useList({
        resource: resources.expertiseScope,
        config: { hasPagination: false }
    });

    const summaryResponse = useOne({ resource: resources.showcaseSummary, id: '' });
    const enumData = summaryResponse?.data?.data;

    const showcaseEnum = {
        [INPUT_STATUS_NAME.all.name]: enumData?.total,
        [INPUT_STATUS_NAME.initiatingStatus.name]: enumData?.init,
        [INPUT_STATUS_NAME.planningStatus.name]: enumData?.planning,
        [INPUT_STATUS_NAME.deliveryStatus.name]: enumData?.delivery,
        [INPUT_STATUS_NAME.evaluationStatus.name]: enumData?.evaluation,
        [INPUT_STATUS_NAME.completed.name]: enumData?.completed,
        [INPUT_STATUS_NAME.published.name]: enumData?.published
    };

    const handleChangeTab = (key: string) => {
        let filters: any[] = [];

        const resetInputStatus = INPUT_STATUS_FIELD_FILTERS.map((item) => ({
            field: item,
            value: STATUS_VALUE.reset,
            operator: 'eq'
        }));
        const resetPublishedStatus = {
            field: INPUT_STATUS_NAME.published.name,
            value: STATUS_VALUE.reset,
            operator: 'eq'
        };

        switch (key) {
            case INPUT_STATUS_NAME.all.name:
                filters = [...resetInputStatus, resetPublishedStatus];
                break;
            case INPUT_STATUS_NAME.completed.name:
                filters = [
                    ...INPUT_STATUS_FIELD_FILTERS.map((item) => ({
                        field: item,
                        value: STATUS_VALUE.done,
                        operator: 'eq'
                    })),
                    resetPublishedStatus
                ];
                break;
            case INPUT_STATUS_NAME.published.name:
                filters = [
                    ...resetInputStatus,
                    {
                        field: INPUT_STATUS_NAME.published.name,
                        value: INPUT_STATUS_NAME.published.value,
                        operator: 'eq'
                    }
                ];
                break;
            default:
                filters = [
                    ...INPUT_STATUS_FIELD_FILTERS.map((item) => ({
                        field: item,
                        value: item === key ? STATUS_VALUE.inputting : STATUS_VALUE.reset,
                        operator: 'eq'
                    })),
                    resetPublishedStatus
                ];
                break;
        }

        setFilters(filters);
    };

    const handleDuplicateShowcase = (id: string | number | undefined) =>
        mutate(
            {
                resource: urlcat(SHOWCASE_ENDPOINTS.duplicate, { id }),
                values: {},
                successNotification: (data: any) => ({
                    message: `Showcase ${data?.data?.businessPartner.brandName} is duplicated successfully`,
                    description: 'Success',
                    type: 'success'
                })
            },
            {
                onSuccess: () => {
                    return tableQueryResult.refetch();
                }
            }
        );

    const handleTogglePublishShowcase = (
        id: string | number | undefined,
        status: 'publish' | 'unpublish'
    ) =>
        mutate(
            {
                resource: urlcat(SHOWCASE_ENDPOINTS[status], { id }),
                values: {},
                successNotification: (data: any) => ({
                    message: `Showcase ${data?.data?.businessPartner.brandName} is ${status}ed`,
                    description: 'Success',
                    type: 'success'
                })
            },
            {
                onSuccess: () => {
                    return tableQueryResult.refetch();
                }
            }
        );

    return {
        tableProps,
        filters,
        expertiseScopes,
        searchFormProps,
        createLoading,
        showcaseEnum,

        handleChangeTab,
        handleDuplicateShowcase,
        handleTogglePublishShowcase
    };
};

export { useShowcaseList };
