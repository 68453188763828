export const getToken = (tokenName: string) => localStorage.getItem(tokenName);

export const setToken = (tokenName: string, token: string) =>
    localStorage.setItem(tokenName, token);

export const removeToken = (tokenName: string) => {
    localStorage.removeItem(tokenName);
};

export const mapArrToObject = (arr: any[] = [], key: string | number) => {
    const obj: any = {};
    arr.forEach((element: any) => {
        obj[element[key.toString()]] = {
            ...element
        };
    });
    return obj;
};
