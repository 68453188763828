import { useNavigation, useLogin } from '@pankod/refine-core';
import { Spin } from '@pankod/refine-antd';
import { useParams } from '@pankod/refine-react-router-v6';

export const Authentication = () => {
    const { replace } = useNavigation();

    const params = useParams();
    const { mutate: login } = useLogin();

    if (!params?.token) {
        replace('/login');
    }

    login({ token: params?.token });
    replace('/');

    return <Spin>Authenticating...</Spin>;
};
