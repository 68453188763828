import { values } from 'lodash';
export const LIST_EXPERTISE = [];

type InputStatusKey = {
    [key: string]: any;
};
export const INPUT_STATUS_NAME: InputStatusKey = {
    all: { label: 'All', name: 'total' },
    initiatingStatus: { label: 'Initiating', name: 'initiatingStatus', color: 'processing' },
    planningStatus: { label: 'Planning', name: 'planningStatus', color: 'processing' },
    deliveryStatus: { label: 'Delivery', name: 'deliveryStatus', color: 'processing' },
    evaluationStatus: {
        label: 'Evaluating & Closing',
        name: 'evaluationStatus',
        color: 'processing'
    },
    completed: { label: 'Completed', name: 'completed', color: 'green' },
    published: { label: 'Published', name: 'published', value: 'published', color: 'purple' }
};

export const INPUT_STATUS_TABS = values(INPUT_STATUS_NAME);

export const INPUT_STATUS_FIELD_FILTERS = [
    INPUT_STATUS_NAME.initiatingStatus.name,
    INPUT_STATUS_NAME.planningStatus.name,
    INPUT_STATUS_NAME.deliveryStatus.name,
    INPUT_STATUS_NAME.evaluationStatus.name
];

export const STATUS_VALUE = {
    unKnown: 0, // User have not inputting phase yet.
    inputting: 1,
    done: 2,
    reset: undefined
};

export const RESET_FILTER_VALUE = undefined;

export const TOKEN_NAME = 'atlas-token';

// Master Data
export const MS_CUSTOM_FILED = {
    optionLabel: 'name',
    optionValue: 'id'
};

export const DATE_INPUT_FORMAT = 'DD/MM/YYYY';

export const FILE_TYPE = {
    file: 'file',
    thumbnail: 'thumbnail',
    image: 'image'
};

export const FORM_STEPS = [
    { step: 1, title: 'Initiating' },
    { step: 2, title: 'Planning' },
    { step: 3, title: 'Delivery' },
    { step: 4, title: 'Evaluating & Closing' }
];

export const MAX_PROCESS_UPLOAD = 3;

// All field of expertise scope
export const ALL_FIELD_ES = [
    'id',
    'expertiseScopeId',

    'estimationScope',
    'estimationCost',
    'estimationComplexity',
    'estimationSenior',
    'estimationMiddle',
    'estimationJunior',

    'actualScope',
    'actualCost',
    'actualComplexity',
    'actualSenior',
    'actualMiddle',
    'actualJunior',

    'expertiseScope',
    'solution',
    'approach',
    'output',
    'links',
    'files'
];

export const PLAN_FIELDS = [
    'estimationScope',
    'estimationCost',
    'estimationComplexity',
    'estimationSenior',
    'estimationMiddle',
    'estimationJunior'
];

export const ACTUAL_FIELDS = [
    'actualScope',
    'actualCost',
    'actualComplexity',
    'actualSenior',
    'actualMiddle',
    'actualJunior'
];

export const PLATFORMS = {
    ios: 'ios',
    android: 'android',
    website: 'website'
};

export const DEFAULT_PARAMS = {
    order: 'DESC',
    sort: 'createdAt',
    take: 10
};

type SortColumnValue = {
    [key: string]: string;
};
export const SORT_COLUMN_VALUE: SortColumnValue = {
    businessPartner: 'brandName'
};

export const OPTION_ALL = 'all';
