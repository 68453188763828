import React from 'react';
import { Select } from 'antd';
import type { SelectProps } from 'antd';

import { Checked } from 'components/Icons';
import { OPTION_ALL } from 'utils';

import styles from './styles.module.scss';

type CustomSelectProps = SelectProps & {
    mode?: 'multiple' | 'tags';
    allowSelectAll?: boolean;
    resource?: string;
    handleCreateNewData?: (value: string | number) => void;
    handleSelectAll?: (value: string | number) => void;
};

const CustomSelect = ({
    mode = 'multiple',
    allowSelectAll = false,
    options,
    className,
    value,
    handleCreateNewData = () => {
        return;
    },
    handleSelectAll = () => null,
    ...rest
}: CustomSelectProps) => {
    const handleOnSelect = (valueSelected: string | number) => {
        if (valueSelected === OPTION_ALL) {
            return handleSelectAll(valueSelected);
        }

        if (options?.some((item) => item?.value === valueSelected)) {
            return;
        }

        if (mode === 'tags') {
            handleCreateNewData(valueSelected);
        }
    };

    const selectedAll = value?.length === options?.length;

    const fullOptions = allowSelectAll
        ? [
              {
                  label: (
                      <div className={styles.selectedCheckAll}>
                          All {selectedAll ? <Checked /> : null}
                      </div>
                  ),
                  value: OPTION_ALL,
                  className: selectedAll ? 'ant-select-item-option-selected' : null
              },
              ...(options || [])
          ]
        : options;

    return (
        <Select
            mode={mode}
            style={{ width: '100%' }}
            onSelect={handleOnSelect}
            options={fullOptions}
            className={`${styles.customSelect} ${className}`}
            allowClear={true}
            optionFilterProp="label"
            showArrow={true}
            size="large"
            value={value}
            {...rest}
        />
    );
};

export default CustomSelect;
