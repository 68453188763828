import { TagField, TagFieldProps } from '@pankod/refine-antd';

import styles from './styles.module.scss';

type CustomTagFieldProps = TagFieldProps;

export default function CustomTagField({ className, ...rest }: CustomTagFieldProps) {
    return (
        <span className={`${styles.tagFieldContainer} ${className}`}>
            <TagField {...rest} />
        </span>
    );
}
