import * as React from 'react';
const SearchIcon = (props) => (
    <svg
        width={18}
        height={18}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.036 2.85a5.186 5.186 0 1 0 0 10.372 5.186 5.186 0 0 0 0-10.372Zm4.92 9.257a6.386 6.386 0 1 0-.849.848l3.22 3.22a.6.6 0 0 0 .848-.849l-3.219-3.219Z"
            fill="#000D0B"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.575 8.035a6.46 6.46 0 1 1 11.482 4.066l3.17 3.17a.675.675 0 0 1-.954.956l-3.17-3.171a6.46 6.46 0 0 1-10.527-5.02Zm6.46-6.31a6.31 6.31 0 1 0 4.024 11.172l.053-.044 3.267 3.267a.525.525 0 0 0 .742-.742l-3.267-3.267.044-.053A6.31 6.31 0 0 0 8.036 1.724Zm0 1.2a5.11 5.11 0 1 0 0 10.22 5.11 5.11 0 0 0 0-10.22Zm-5.26 5.11a5.26 5.26 0 1 1 9.004 3.697l-.003.003a.473.473 0 0 0-.04.04l-.003.003a5.26 5.26 0 0 1-8.957-3.743Z"
            fill="#000D0B"
        />
    </svg>
);
export default SearchIcon;
