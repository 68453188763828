import { Row, Col, Form, Typography, Table } from '@pankod/refine-antd';

import { CustomRangePicker } from 'components/CustomRangePicker';
import CustomTextArea from 'components/CustomTextArea';
import { planningPhase, evaluatingPhase } from 'pages/showcase/schema';
import { StatisticData } from 'interfaces';

import styles from './styles.module.scss';

type EvaluatingAndClosingProps = {
    statisticalESData?: StatisticData[];
};

const { Title, Text } = Typography;

const { statistic } = planningPhase.program;
const { program, product, businessFeedback, userFeedback, techFeedback } = evaluatingPhase;
const { evaluationTimeline } = program;

const columns = [
    {
        title: ' ',
        dataIndex: 'rowLabel',
        width: 500,
        render: (text: string) => <Text>{text}</Text>
    },
    {
        title: <Text className={styles.numberColumnTitle}>Scope (point)</Text>,
        dataIndex: 'scope',
        width: 500,
        render: (value: number) => <div className={styles.tableCell}>{value}</div>
    },
    {
        title: <Text className={styles.numberColumnTitle}>Cost (hour)</Text>,
        dataIndex: 'cost',
        width: 500,
        render: (value: number) => <div className={styles.tableCell}>{value}</div>
    }
];

export default function EvaluatingAndClosing({ statisticalESData }: EvaluatingAndClosingProps) {
    return (
        <>
            <div>
                <Title level={4}>{program.label}</Title>
                <Row>
                    <Text className={styles.labelTable}>{statistic}</Text>

                    <Col span={24}>
                        <Table
                            className={styles.statisticTable}
                            dataSource={statisticalESData}
                            columns={columns}
                            bordered
                            rowKey="rowKey"
                            pagination={{ position: [] }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Form.Item
                            label={evaluationTimeline.label}
                            name={[program.name, evaluationTimeline.name]}>
                            <CustomRangePicker disabled suffixIcon={<></>} />
                        </Form.Item>
                    </Col>
                </Row>

                <div>
                    <Title level={4}>{product.label}</Title>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label={product.expectedGoal.label}
                                name={[product.name, product.expectedGoal.name]}>
                                <CustomTextArea autoSize size="large" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name={[product.name, product.solution.name]}
                                label={product.solution.label}>
                                <CustomTextArea
                                    placeholder={product.solution.placeholder}
                                    autoSize
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>

                <div>
                    <Title level={4}>{businessFeedback.label}</Title>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label={businessFeedback.businessResult.label}
                                tooltip={businessFeedback.businessResult.tooltip}
                                name={[businessFeedback.name]}>
                                <CustomTextArea
                                    autoSize
                                    size="large"
                                    placeholder={businessFeedback.businessResult.placeholder}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>

                <div>
                    <Title level={4}>{userFeedback.label}</Title>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label={userFeedback.userReview.label}
                                tooltip={userFeedback.userReview.tooltip}
                                name={[userFeedback.name]}>
                                <CustomTextArea
                                    autoSize
                                    size="large"
                                    placeholder={userFeedback.userReview.placeholder}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>

                <div>
                    <Title level={4}>{techFeedback.label}</Title>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label={techFeedback.techReview.label}
                                tooltip={techFeedback.techReview.tooltip}
                                name={[techFeedback.name]}>
                                <CustomTextArea
                                    autoSize
                                    size="large"
                                    placeholder={techFeedback.techReview.placeholder}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}
