export const AUTHENTICATE_API = '/authentication/otable';

export const UPLOAD = {
    file: '/file',
    thumbnail: '/file/thumbnail',
    image: '/file/image'
};

export const SHOWCASE_ENDPOINTS = {
    duplicate: 'showcase/:id/duplicate',
    publish: 'showcase/:id/publish',
    unpublish: 'showcase/:id/unpublish'
};
