import { Button } from '@pankod/refine-antd';
import urlcat from 'urlcat';

import { API_URL } from 'configs/environments';
import { AUTHENTICATE_API } from 'configs/api';

import styles from './styles.module.scss';

export const Login = () => {
    const redirectUrl = urlcat(API_URL, AUTHENTICATE_API);

    return (
        <div className={styles.main}>
            <div className={styles.loginBox}>
                <img src="/logo/logo-simple.svg" alt="logo" />
                <h3>LOGIN</h3>
                <Button type="primary" href={redirectUrl}>
                    Login with OTable
                </Button>
            </div>
        </div>
    );
};
