import { Refine } from '@pankod/refine-core';
import {
    notificationProvider,
    ReadyPage,
    ErrorComponent,
    ConfigProvider
} from '@pankod/refine-antd';
import '@pankod/refine-antd/dist/reset.css';

import routerProvider from '@pankod/refine-react-router-v6';

import { API_URL } from './configs/environments';
import { token, components } from './configs/theme';

import AdminLayout from 'layouts/AdminLayout';
import { ShowcaseList, ShowcaseCreate, ShowcaseEdit, Login, Authentication } from 'pages';
import { dataProvider, authProvider } from 'providers';

import resources from 'utils/resources';

import './index.css';

function App() {
    return (
        <ConfigProvider theme={{ components, token }}>
            <Refine
                authProvider={authProvider}
                LoginPage={Login}
                dataProvider={dataProvider(API_URL)}
                notificationProvider={notificationProvider}
                Layout={AdminLayout}
                ReadyPage={ReadyPage}
                catchAll={<ErrorComponent />}
                resources={[
                    {
                        name: resources.showcase,
                        list: ShowcaseList,
                        create: ShowcaseCreate,
                        edit: ShowcaseEdit,
                        options: { label: 'Product Library' }
                    }
                ]}
                routerProvider={{
                    ...routerProvider,
                    routes: [
                        {
                            element: <Authentication />,
                            path: '/authorized/:token'
                        }
                    ] as typeof routerProvider.routes
                }}
            />
        </ConfigProvider>
    );
}

export default App;
