import { PromisePool } from '@supercharge/promise-pool';

import dayjs from 'dayjs';
import { toString, pick, pickBy, isArray, isObject, isEmpty } from 'lodash';

import type { MasterData, ShowcaseFile, ExpertiseScope, Showcase, ProductTag } from 'interfaces';
import { mapArrToObject, MAX_PROCESS_UPLOAD, ALL_FIELD_ES, STATUS_VALUE } from 'utils';
import {
    initiatingPhase,
    planningPhase,
    expertiseScope,
    evaluatingPhase,
    INITIATING_FIELDS,
    PLANNING_FIELDS,
    DELIVERY_FIELDS,
    EVALUATING_FIELDS
} from 'pages/showcase/schema';
import { formatFormLinks, handleUploadFile } from '../functions';

const { businessPartner, partnership, product } = initiatingPhase;
const { brandName, industry, businessNature, type, context, businessExpectedGoal, problem } =
    businessPartner;
const { partnershipModel, servicePackages } = partnership;
const { tags, productExpertiseScopes, fileUpload, thumbnail, productMaturity } = product;

const { program } = planningPhase;
const { programExpectedGoal, programSpecificCondition, deliverable, timeline } = program;

const { scope, cost, complexity, combination, solution, approach, output, links, demo } =
    expertiseScope;

const hasValue = (field: any) => {
    if (isArray(field)) return field.length;
    if (isObject(field)) return !isEmpty(field);
    return field;
};
const isDoneInitiatingPhase = (formValues: any, fields: any) =>
    fields.some((item: any) => {
        if (!item.child.length) return hasValue(formValues[item.name]);
        return item.child.some((_it: any) => {
            if (hasValue(formValues[item.name]?.[_it])) {
                return true;
            }
        });
    });

const formatShowcase = async (
    formValues: any,
    initialValue: any = {},
    estimateTotalScope: number,
    estimateTotalCost: number
) => {
    // console.log('formValues', formValues);

    const businessPartnerPayload = {
        id: formValues[businessPartner.name]?.id,
        brandName: formValues[businessPartner.name]?.[brandName.name],
        industries:
            formValues[businessPartner.name]?.[industry.name] &&
            formValues[businessPartner.name]?.[industry.name].map(
                (item: Partial<MasterData> & number) => ({
                    id: item?.id || item
                })
            ),
        businessNature: formValues[businessPartner.name]?.[businessNature.name],
        type: formValues[businessPartner.name]?.[type.name],
        context: formValues[businessPartner.name]?.[context.name],
        expectedGoal: formValues[businessPartner.name]?.[businessExpectedGoal.name],
        problem: formValues[businessPartner.name]?.[problem.name]
    };

    const partnershipPayload = {
        id: formValues[partnership.name]?.id,
        partnershipModel: formValues[partnership.name]?.[partnershipModel.name],
        servicePackages:
            formValues[partnership.name]?.[servicePackages.name] &&
            formValues[partnership.name]?.[servicePackages.name].map(
                (item: Partial<MasterData> & number) => ({ id: item?.id || item })
            )
    };

    const productPayload = {
        id: formValues[product.name]?.id,
        productMaturity: formValues[product.name]?.[productMaturity.name],
        productTags:
            formValues[product.name]?.[tags.name] &&
            formValues[product.name]?.[tags.name].map((item: ProductTag) => {
                const productTagId = item?.productTagId || item;
                const selectedTags = mapArrToObject(
                    initialValue?.product?.productTags || [],
                    'productTagId'
                );

                return {
                    id: selectedTags[toString(productTagId)]?.id,
                    productTagId,
                    appearShowcase: (formValues[product.name]?.[tags.subFieldName] || []).includes(
                        productTagId
                    )
                };
            })
    };

    const programPayload = {
        id: formValues[program.name]?.id,
        expectedGoal: formValues[program.name]?.[programExpectedGoal.name],
        programSpecificCondition: formValues[program.name]?.[programSpecificCondition.name],
        planningStartTime: formValues[program.name]?.[timeline.name]
            ? dayjs(formValues[program.name]?.[timeline.name][0]).format()
            : formValues[program.name]?.[timeline.name],
        planningEndTime: formValues[program.name]?.[timeline.name]
            ? dayjs(formValues[program.name]?.[timeline.name][1]).format()
            : formValues[program.name]?.[timeline.name],
        deliverables:
            formValues[program.name]?.[deliverable.name] &&
            formValues[program.name]?.[deliverable.name].map((item: MasterData) => ({
                id: item?.id || item
            })),
        totalEstimationScope: estimateTotalScope,
        totalEstimationCost: estimateTotalCost
    };

    const data: Showcase = {
        businessPartner: {}
    };

    data.businessPartner = pickBy(businessPartnerPayload);
    data.partnership = pickBy(partnershipPayload);
    data.product = pickBy(productPayload);
    data.program = pickBy(programPayload);

    // Handle File upload
    let files: ShowcaseFile[] = [];
    if (formValues[thumbnail.name]) {
        if (!formValues[thumbnail.name]?.fileList) {
            files = (formValues[thumbnail.name] || []).map((item: ShowcaseFile) => ({
                id: item?.id
            }));
        } else {
            const currentFiles: any[] = [];
            const unUploadFiles: any[] = [];

            (formValues[thumbnail.name].fileList || []).forEach((item: ShowcaseFile) => {
                if (item?.id) {
                    currentFiles.push(item.id);
                } else {
                    unUploadFiles.push(item);
                }
            });

            const thumbnailIds = await handleUploadFile(unUploadFiles, thumbnail.apiUpload);
            files = [...currentFiles, ...thumbnailIds];
        }
    }

    if (formValues[fileUpload.name]) {
        if (!formValues[fileUpload.name]?.fileList) {
            files = [
                ...files,
                ...formValues[fileUpload.name].map((item: ShowcaseFile) => ({ id: item?.id }))
            ];
        } else {
            const currentFiles: any[] = [];
            const unUploadFiles: any[] = [];

            (formValues[fileUpload.name].fileList || []).forEach((item: ShowcaseFile) => {
                if (item?.id) {
                    currentFiles.push({ id: item.id });
                } else {
                    unUploadFiles.push(item);
                }
            });

            const uploadIds = await handleUploadFile(unUploadFiles, fileUpload.apiUpload);
            files = [...files, ...currentFiles, ...uploadIds];
        }
    }

    if (files.length) {
        data.files = files;
    }

    // Expertise Scope
    let scopes: ExpertiseScope[] = [];
    if (formValues[product.name]?.[productExpertiseScopes.name]) {
        const { results } = await PromisePool.for(
            formValues[product.name]?.[productExpertiseScopes.name] || []
        )
            .withConcurrency(MAX_PROCESS_UPLOAD)
            .process(async (scopeValue: any) => {
                const scopeId = scopeValue?.expertiseScopeId || scopeValue;
                const estimationData = {
                    ...pick(formValues[planningPhase.estimation.name]?.[toString(scopeId)], [
                        scope.plan.name,
                        cost.plan.name,
                        complexity.plan.name,
                        combination.plan.senior.name,
                        combination.plan.junior.name,
                        combination.plan.middle.name
                    ])
                };

                const deliveryPhase = formValues[expertiseScope.name]?.[toString(scopeId)];

                const deliveryData = {
                    ...deliveryPhase?.plan,
                    ...deliveryPhase?.actual,
                    ...pick(deliveryPhase, [solution.name, approach.name, output.name])
                };

                if (deliveryPhase?.[links.name]) {
                    const androidLinks = formatFormLinks(
                        deliveryPhase[links.name]?.android,
                        links.android.name
                    );

                    const iosLinks = formatFormLinks(
                        deliveryPhase[links.name]?.ios,
                        links.ios.name
                    );

                    const websiteLink = formatFormLinks(
                        deliveryPhase[links.name]?.website,
                        links.website.name
                    );

                    deliveryData.links = [...androidLinks, ...iosLinks, ...websiteLink];
                }

                let demoFiles: ShowcaseFile[] = [];
                if (deliveryPhase?.[demo.name]) {
                    if (!deliveryPhase?.[demo.name]?.fileList) {
                        demoFiles = deliveryPhase?.[demo.name];
                    } else {
                        const currentFiles: any[] = [];
                        const unUploadFiles: any[] = [];

                        (deliveryPhase?.[demo.name].fileList || []).forEach(
                            (file: ShowcaseFile) => {
                                if (file?.id) {
                                    currentFiles.push({ id: file.id });
                                } else {
                                    unUploadFiles.push(file);
                                }
                            }
                        );

                        const uploadIds = await handleUploadFile(unUploadFiles, demo.apiUpload);
                        demoFiles = [...currentFiles, ...uploadIds];
                    }
                }

                deliveryData.files = demoFiles;

                return {
                    id: deliveryPhase?.id,
                    expertiseScopeId: scopeId,
                    ...pickBy(estimationData),
                    ...pickBy(
                        pick(
                            deliveryData,
                            ALL_FIELD_ES.filter((item) => item !== 'expertiseScope')
                        )
                    )
                };
            });

        scopes = results;
    }

    if (scopes.length) {
        data.expertiseScopes = scopes;
    }

    // Evaluating phase
    const evaluatingTimeline =
        formValues[evaluatingPhase.program.name]?.[evaluatingPhase.program.evaluationTimeline.name];

    data.program = {
        ...data.program,
        evaluationStartTime: dayjs(evaluatingTimeline?.[0]).format() // Get current time. If it has default value -> using default value
    };

    data.product = {
        ...data.product,
        solution: formValues[evaluatingPhase.product.name]?.[evaluatingPhase.product.solution.name],
        expectedGoal:
            formValues[evaluatingPhase.product.name]?.[evaluatingPhase.product.expectedGoal.name]
    };

    data.businessFeedback = formValues[evaluatingPhase.businessFeedback.name];
    data.userFeedback = formValues[evaluatingPhase.userFeedback.name];
    data.techFeedback = formValues[evaluatingPhase.techFeedback.name];

    data.initiatingStatus = isDoneInitiatingPhase(formValues, INITIATING_FIELDS)
        ? STATUS_VALUE.done
        : STATUS_VALUE.inputting;
    data.planningStatus = isDoneInitiatingPhase(formValues, PLANNING_FIELDS)
        ? STATUS_VALUE.done
        : STATUS_VALUE.inputting;
    data.deliveryStatus = isDoneInitiatingPhase(formValues, DELIVERY_FIELDS)
        ? STATUS_VALUE.done
        : STATUS_VALUE.inputting;
    data.evaluationStatus = isDoneInitiatingPhase(formValues, EVALUATING_FIELDS)
        ? STATUS_VALUE.done
        : STATUS_VALUE.inputting;

    if (data.evaluationStatus === STATUS_VALUE.done) {
        data.program = {
            ...data.program,
            evaluationEndTime: dayjs(new Date()).format()
        };
    }

    // console.log('DATA SUBMIT', data);
    return data;
};

export default formatShowcase;
