import { Grid, Layout as AntdLayout } from 'antd';
import { RefineLayoutLayoutProps, Header as DefaultHeader } from '@pankod/refine-antd';

import CustomSider from 'components/CustomSider';
import UserIdentity from 'components/UserIdentity';

import styles from './styles.module.scss';

export default function AdminLayout({
    children,
    Header,
    Footer,
    OffLayoutArea,
    Sider,
    Title,
    ...rest
}: RefineLayoutLayoutProps) {
    const breakpoint = Grid.useBreakpoint();
    const SiderToRender = CustomSider ?? Sider;
    const HeaderToRender = UserIdentity ?? DefaultHeader;
    const isSmall = typeof breakpoint.sm === 'undefined' ? true : breakpoint.sm;

    return (
        <AntdLayout style={{ minHeight: '100vh' }} {...rest}>
            <div className={styles.headerContainer}>
                <SiderToRender />
                <HeaderToRender />
            </div>
            <AntdLayout.Content>
                <div
                    style={{
                        minHeight: 360,
                        padding: isSmall ? 24 : 12
                    }}>
                    {children}
                </div>
                {OffLayoutArea && <OffLayoutArea />}
            </AntdLayout.Content>
            {Footer && <Footer />}
        </AntdLayout>
    );
}
