import { ShowcaseFile, Showcase } from 'interfaces';
import urlcat from 'urlcat';
import {
    keys,
    pick,
    pickBy,
    forEach,
    isEmpty,
    isObject,
    isString,
    has,
    values,
    sumBy
} from 'lodash';

import {
    ExpertiseScope,
    ExpertiseScopeData,
    ExpertiseScopeFormItem,
    Link,
    ExpertiseScopeLinkField
} from 'interfaces';
import { axiosInstance } from 'providers/utils';
import { API_URL } from 'configs/environments';
import { ALL_FIELD_ES, PLAN_FIELDS, ACTUAL_FIELDS, PLATFORMS } from 'utils';

import { expertiseScope } from '../schema';

const { scope, cost } = expertiseScope;

const formatFormLinks = (links: ExpertiseScopeLinkField[] = [], type: string) =>
    links
        .filter((item) => item)
        .map((item: ExpertiseScopeLinkField) => ({
            id: item?.id,
            url: item?.url || item,
            type
        }));

const formatFileToShows = (files: ShowcaseFile[] = []) =>
    files.map((item) => ({
        id: item?.id,
        uid: item?.id,
        name: item?.fileName,
        url: item?.path,
        status: 'done'
    }));

const handleUploadFile = async (fileList: any[], api: string) => {
    const url = urlcat(API_URL, api);

    const values = await Promise.all(
        fileList.map(async (file: any) => {
            const data = new FormData();
            data.append('file', file.originFileObj);

            const res = await axiosInstance.post(url, data, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            return { id: res.data?.id };
        })
    );

    return values;
};

const formatExpertiseScopeFormItem = (expertiseScopes: ExpertiseScope[] = []) => {
    const result: ExpertiseScopeData = {};

    forEach(expertiseScopes, (scope: ExpertiseScope) => {
        const data: ExpertiseScopeFormItem = pick(scope, ...ALL_FIELD_ES);
        data.value = scope.expertiseScopeId;
        data.label = scope?.expertiseScope?.name;
        data.plan = pick(scope, PLAN_FIELDS);
        data.actual = pick(scope, ACTUAL_FIELDS);
        data.demoScreen = formatFileToShows(scope?.files);

        const iosLinks: ExpertiseScopeLinkField[] = [];
        const androidLinks: ExpertiseScopeLinkField[] = [];
        const websiteLinks: ExpertiseScopeLinkField[] = [];

        // console.log('scope?.links', scope?.links);

        forEach(scope?.links, (value: Link) => {
            const linkPayload = {
                id: value?.id,
                value: value.url,
                url: value.url
            };
            switch (value.type) {
                case PLATFORMS.ios:
                    iosLinks.push(linkPayload);
                    break;
                case PLATFORMS.android:
                    androidLinks.push(linkPayload);
                    break;
                case PLATFORMS.website:
                    websiteLinks.push(linkPayload);
                    break;
                default:
                    websiteLinks.push(linkPayload);
            }
        });

        const platformLinks = { ios: iosLinks, android: androidLinks, website: websiteLinks };
        data.platformLinks = pickBy(platformLinks, (item) => !isEmpty(item));

        result[scope?.expertiseScopeId] = {
            ...pickBy(data, (item) => (isObject(item) ? !isEmpty(item) : item))
        };
    });
    return result;
};

const formatValueEstimationTable = (expertiseScopes: ExpertiseScopeData) => {
    const result: ExpertiseScopeData = {};
    forEach(expertiseScopes, (value, key) => {
        result[key] = {
            ...value,
            ...value.plan,
            ...value.actual
        };
    });
    return result;
};

const countTotalScopeCost = (expertiseScopes: ExpertiseScopeData) => {
    const ESArr = values(expertiseScopes);
    return {
        planScope: sumBy(ESArr, scope.plan.name),
        actualScope: sumBy(ESArr, scope.actual.name),
        planCost: sumBy(ESArr, cost.plan.name),
        actualCost: sumBy(ESArr, cost.actual.name)
    };
};

export {
    formatFormLinks,
    handleUploadFile,
    formatFileToShows,
    formatExpertiseScopeFormItem,
    formatValueEstimationTable,
    countTotalScopeCost
};
