import { AxiosInstance } from 'axios';
import { stringify } from 'query-string';
import { DataProvider } from '@pankod/refine-core';
import urlcat from 'urlcat';
import { toUpper, isArray, pickBy, pick } from 'lodash';

import { axiosInstance, generateSort, generateFilter } from './utils';

import { DEFAULT_PARAMS, SORT_COLUMN_VALUE, INPUT_STATUS_FIELD_FILTERS } from 'utils';

export const dataProvider = (
    apiUrl: string,
    httpClient: AxiosInstance = axiosInstance
): Omit<Required<DataProvider>, 'createMany' | 'updateMany' | 'deleteMany'> => ({
    getList: async ({
        resource,
        hasPagination = true,
        pagination = { current: 1, pageSize: 10 },
        filters,
        sort
    }) => {
        const url = `${apiUrl}/${resource}`;

        const { current = 1, pageSize = 10 } = pagination ?? {};

        const queryFilters = generateFilter(filters);

        const query: {
            _sort?: string;
            _order?: string;
            page?: number;
            take?: number;
        } = hasPagination
            ? {
                  page: current,
                  take: pageSize
              }
            : {};

        const generatedSort = generateSort(sort);
        if (generatedSort) {
            const { _sort, _order } = generatedSort;
            query._sort =
                _sort
                    .map((item) => (SORT_COLUMN_VALUE[item] ? SORT_COLUMN_VALUE[item] : item))
                    .join(',') || DEFAULT_PARAMS.sort;
            query._order = _order.join(',') || DEFAULT_PARAMS.order;
        }

        const filterFields: any = {
            q: queryFilters?.q,
            status: queryFilters.published,
            expertiseScope:
                isArray(queryFilters?.expertiseScopes) && queryFilters?.expertiseScopes.join(','),
            ...pick(queryFilters, INPUT_STATUS_FIELD_FILTERS)
        };

        const response = await httpClient.get(
            urlcat(url, {
                order: query._order && toUpper(query._order),
                orderBy: query._sort,
                page: query.page,
                take: query.take,
                ...pickBy(filterFields, (item) => item)
            })
        );

        const { data } = response || {};

        const total = data?.meta?.itemCount || 0;

        return {
            data: data?.data || [],
            total
        };
    },

    getMany: async ({ resource, ids }) => {
        const { data } = await httpClient.get(`${apiUrl}/${resource}?${stringify({ id: ids })}`);

        return {
            data
        };
    },

    create: async ({ resource, variables }) => {
        const url = `${apiUrl}/${resource}`;

        const { data } = await httpClient.post(url, variables);

        return {
            data
        };
    },

    update: async ({ resource, id, variables }) => {
        const url = `${apiUrl}/${resource}/${id}`;

        const { data } = await httpClient.put(url, variables);

        return {
            data
        };
    },

    getOne: async ({ resource, id }) => {
        const url = `${apiUrl}/${resource}/${id}`;

        const { data } = await httpClient.get(url);

        return {
            data
        };
    },

    deleteOne: async ({ resource, id, variables }) => {
        const url = `${apiUrl}/${resource}/${id}`;

        const { data } = await httpClient.delete(url, {
            data: variables
        });

        return {
            data
        };
    },

    getApiUrl: () => {
        return apiUrl;
    },

    custom: async ({ url, method, filters, sort, payload, query, headers }) => {
        let requestUrl = `${url}?`;

        if (sort) {
            const generatedSort = generateSort(sort);
            if (generatedSort) {
                const { _sort, _order } = generatedSort;
                const sortQuery = {
                    _sort: _sort.join(','),
                    _order: _order.join(',')
                };
                requestUrl = `${requestUrl}&${stringify(sortQuery)}`;
            }
        }

        if (filters) {
            const filterQuery = generateFilter(filters);
            requestUrl = `${requestUrl}&${stringify(filterQuery)}`;
        }

        if (query) {
            requestUrl = `${requestUrl}&${stringify(query)}`;
        }

        if (headers) {
            httpClient.defaults.headers = {
                ...httpClient.defaults.headers,
                ...headers
            };
        }

        let axiosResponse;
        switch (method) {
            case 'put':
            case 'post':
            case 'patch':
                axiosResponse = await httpClient[method](url, payload);
                break;
            case 'delete':
                axiosResponse = await httpClient.delete(url, {
                    data: payload
                });
                break;
            default:
                axiosResponse = await httpClient.get(requestUrl);
                break;
        }

        const { data } = axiosResponse;

        return Promise.resolve({ data });
    }
});
