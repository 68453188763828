import React, { useState } from 'react';

import {
    Row,
    Col,
    Form,
    Input,
    Typography,
    Table,
    Collapse,
    Button,
    Modal,
    Radio,
    RadioChangeEvent
} from '@pankod/refine-antd';
import { UseSelectReturnType } from '@pankod/refine-core';
import { toString } from 'lodash';

import CustomDragger from 'components/CustomDragger';
import EstimationInputNumber from 'components/EstimationInputNumber';
import { Plus, GarbageCan } from 'components/Icons';
import { expertiseScope, deliveryPhase, initiatingPhase } from 'pages/showcase/schema';
import { ExpertiseScope, ExpertiseScopeData } from 'interfaces';
import styles from './styles.module.scss';

type DeliveryProps = {
    expertiseScopeSelected: ExpertiseScope[];
    expertiseSelectProps?: UseSelectReturnType;
    initialValues?: ExpertiseScopeData;
    handleAddMoreExpertiseScope: (value: string | number) => void;
    handleRemoveExpertiseScope: (value: string | number) => void;
};

type ExpertiseTable = ExpertiseScope & {
    rowKey: 'plan' | 'actual';
    rowName: string;
};

const { Title, Text } = Typography;
const { TextArea } = Input;
const { Panel } = Collapse;

const { name, scope, cost, complexity, combination, solution, approach, output, links, demo } =
    expertiseScope;
const { android, ios, website } = links;

const columns = [
    {
        title: ' ',
        dataIndex: 'rowName',
        render: (text: string) => <Text>{text}</Text>
    },
    {
        title: <Text className={styles.numberColumnTitle}>Scope (point)</Text>,
        dataIndex: 'estimationScope',
        render: (_: string, { rowKey, expertiseScopeId }: ExpertiseTable) => (
            <Form.Item
                name={[toString(expertiseScopeId), rowKey, scope[rowKey].name]}
                className={styles.inputNumberContainer}>
                <EstimationInputNumber />
            </Form.Item>
        )
    },
    {
        title: <Text className={styles.numberColumnTitle}>Cost (hour)</Text>,
        dataIndex: 'estimationCost',
        render: (_: string, { rowKey, expertiseScopeId }: ExpertiseTable) => (
            <Form.Item
                name={[toString(expertiseScopeId), rowKey, cost[rowKey].name]}
                className={styles.inputNumberContainer}>
                <EstimationInputNumber />
            </Form.Item>
        )
    },
    {
        title: <Text className={styles.numberColumnTitle}>Complexity</Text>,
        dataIndex: 'planComplexity',
        render: (_: string, { rowKey, expertiseScopeId }: ExpertiseTable) => (
            <Form.Item
                name={[toString(expertiseScopeId), rowKey, complexity[rowKey].name]}
                className={styles.inputNumberContainer}>
                <EstimationInputNumber />
            </Form.Item>
        )
    },
    {
        title: combination.label,
        children: [
            {
                title: <Text className={styles.numberColumnTitle}>Senior</Text>,
                dataIndex: 'senior',
                render: (_: string, { rowKey, expertiseScopeId }: ExpertiseTable) => (
                    <Form.Item
                        name={[toString(expertiseScopeId), rowKey, combination[rowKey].senior.name]}
                        className={styles.inputNumberContainer}>
                        <EstimationInputNumber addonAfter="%" />
                    </Form.Item>
                )
            },
            {
                title: <Text className={styles.numberColumnTitle}>Middle</Text>,
                dataIndex: 'middle',
                render: (_: string, { rowKey, expertiseScopeId }: ExpertiseTable) => (
                    <Form.Item
                        name={[toString(expertiseScopeId), rowKey, combination[rowKey].middle.name]}
                        className={styles.inputNumberContainer}>
                        <EstimationInputNumber addonAfter="%" />
                    </Form.Item>
                )
            },
            {
                title: <Text className={styles.numberColumnTitle}>Junior</Text>,
                dataIndex: 'junior',
                render: (_: string, { rowKey, expertiseScopeId }: ExpertiseTable) => (
                    <Form.Item
                        name={[toString(expertiseScopeId), rowKey, combination[rowKey].junior.name]}
                        className={styles.inputNumberContainer}>
                        <EstimationInputNumber addonAfter="%" />
                    </Form.Item>
                )
            }
        ]
    }
];

export default function Delivery({
    expertiseScopeSelected,
    expertiseSelectProps,
    initialValues,
    handleAddMoreExpertiseScope,
    handleRemoveExpertiseScope
}: DeliveryProps) {
    const [expertiseSelected, setExpertiseSelected] = useState();
    const handleSelectedExpertiseScope = (event: RadioChangeEvent) => {
        setExpertiseSelected(event.target.value);
    };

    const [isAddExpertise, setIsAddExpertise] = useState(false);
    const showAddExpertiseModal = () => {
        setIsAddExpertise(true);
    };

    const handleCancelExpertiseModal = () => {
        setIsAddExpertise(false);
    };

    const handleAddExpertise = () => {
        handleAddMoreExpertiseScope(expertiseSelected || '');
        handleCancelExpertiseModal();
    };

    const expertiseScopeRemaining = expertiseSelectProps?.options.filter(
        (item) =>
            !expertiseScopeSelected.find(
                (_it) => _it.expertiseScopeId.toString() === item.value.toString()
            )
    );
    const [modal, contextHolder] = Modal.useModal();

    return (
        <>
            <div>
                <Title level={4}>{deliveryPhase.label}</Title>
                <Form.List name={[name]}>
                    {(fields) => (
                        <>
                            <Collapse
                                defaultActiveKey={expertiseScopeSelected?.[0]?.expertiseScopeId}
                                className={styles.expertiseScopeContainer}
                                ghost>
                                {expertiseScopeSelected.map((item: ExpertiseScope) => {
                                    const { expertiseScopeId, expertiseScope } = item;

                                    const dataSrc: ExpertiseTable[] = [
                                        {
                                            rowName: 'Plan',
                                            rowKey: 'plan',
                                            ...item
                                        },
                                        {
                                            rowName: 'Actual',
                                            rowKey: 'actual',
                                            ...item
                                        }
                                    ];

                                    const linkTypes: any[] = [android, ios, website];
                                    const demoScreen: any[] =
                                        (initialValues || {})[expertiseScopeId]?.demoScreen || [];

                                    return (
                                        <Panel
                                            header={item?.expertiseScope?.name}
                                            key={expertiseScopeId}
                                            className={styles.expertiseScopeItem}
                                            extra={
                                                <GarbageCan
                                                    onClick={() => {
                                                        modal.confirm({
                                                            icon: <></>,
                                                            title: (
                                                                <Title level={4}>
                                                                    Do you want to delete{' '}
                                                                    {item?.expertiseScope?.name}?
                                                                </Title>
                                                            ),
                                                            content:
                                                                'This will delete this expertise scope permanently. You cannot undo this action',
                                                            onOk: () =>
                                                                handleRemoveExpertiseScope(
                                                                    expertiseScopeId
                                                                )
                                                        });
                                                    }}
                                                />
                                            }>
                                            <Table
                                                className={styles.statisticTable}
                                                dataSource={dataSrc}
                                                columns={columns}
                                                bordered
                                                rowKey="rowKey"
                                                pagination={{ position: [] }}
                                            />

                                            <Row>
                                                <Col span={24}>
                                                    <Form.Item
                                                        name={[expertiseScopeId, solution.name]}
                                                        label={solution.label}>
                                                        <TextArea
                                                            placeholder={solution.placeholder}
                                                            autoSize
                                                            size="large"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={24}>
                                                    <Form.Item
                                                        name={[expertiseScopeId, approach.name]}
                                                        label={approach.label}>
                                                        <TextArea
                                                            placeholder={approach.placeholder}
                                                            autoSize
                                                            size="large"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={24}>
                                                    <Form.Item
                                                        name={[expertiseScopeId, output.name]}
                                                        label={output.label}>
                                                        <TextArea
                                                            placeholder={output.placeholder}
                                                            autoSize
                                                            size="large"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            {expertiseScope?.fields?.includes(demo.fieldSignal) ? (
                                                <Row>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name={[expertiseScopeId, demo.name]}
                                                            label={demo.label}>
                                                            <CustomDragger
                                                                listType="picture"
                                                                placeholder={demo.note}
                                                                accept={demo.accept}
                                                                multiple
                                                                maxCount={demo.limit}
                                                                defaultFileList={demoScreen}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            ) : null}

                                            {linkTypes.map((_it: any) => {
                                                if (
                                                    !expertiseScope?.fields?.includes(
                                                        _it.fieldSignal
                                                    )
                                                ) {
                                                    return null;
                                                }

                                                return (
                                                    <Row key={_it.name}>
                                                        <Text className={styles.labelLinkApp}>
                                                            {_it.label}
                                                        </Text>
                                                        <Col span={24}>
                                                            <Form.List
                                                                name={[
                                                                    expertiseScopeId,
                                                                    links.name,
                                                                    _it.name
                                                                ]}>
                                                                {(fields, { add }) => (
                                                                    <>
                                                                        <div
                                                                            className={
                                                                                fields.length > 1
                                                                                    ? styles.linkContainer
                                                                                    : ''
                                                                            }>
                                                                            {fields.map(
                                                                                (field, index) => (
                                                                                    <Form.Item
                                                                                        className={
                                                                                            styles.linkItem
                                                                                        }
                                                                                        name={[
                                                                                            index,
                                                                                            'url'
                                                                                        ]}
                                                                                        key={index}>
                                                                                        <TextArea
                                                                                            className={
                                                                                                styles.inputLink
                                                                                            }
                                                                                            placeholder={
                                                                                                _it.placeholder
                                                                                            }
                                                                                            autoSize
                                                                                            size="large"
                                                                                        />
                                                                                    </Form.Item>
                                                                                )
                                                                            )}
                                                                        </div>

                                                                        <Button
                                                                            className={
                                                                                styles.addMoreBtn
                                                                            }
                                                                            type="text"
                                                                            onClick={() => add()}>
                                                                            <Plus /> Add more link
                                                                        </Button>
                                                                    </>
                                                                )}
                                                            </Form.List>
                                                        </Col>
                                                    </Row>
                                                );
                                            })}
                                        </Panel>
                                    );
                                })}
                            </Collapse>
                            <Button
                                disabled={!expertiseScopeRemaining?.length}
                                className={`${styles.addMoreBtn} ${styles.addExpertiseScopeBtn}`}
                                type="text"
                                onClick={showAddExpertiseModal}>
                                <Plus /> Add expertise scope
                            </Button>
                        </>
                    )}
                </Form.List>

                <Modal
                    className={styles.addExpertiseModalContainer}
                    title={<Title level={4}>Add expertise scope</Title>}
                    open={isAddExpertise}
                    onOk={handleAddExpertise}
                    okText="Add"
                    onCancel={handleCancelExpertiseModal}>
                    <Radio.Group
                        onChange={handleSelectedExpertiseScope}
                        className={styles.listExpertise}>
                        {(expertiseScopeRemaining || []).map(({ label, value }) => (
                            <Radio key={value + label} value={value}>
                                {label}
                            </Radio>
                        ))}
                    </Radio.Group>
                </Modal>

                <div>{contextHolder}</div>
            </div>
        </>
    );
}
