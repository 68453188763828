import React, { useEffect, useState } from 'react';
import { IResourceComponentsProps, useNavigation } from '@pankod/refine-core';
import { Edit, Form, Steps, useStepsForm, Button, Modal, Typography } from '@pankod/refine-antd';

import { Showcase } from 'interfaces';
import { Initiating, Planning, Delivery, EvaluatingAndClosing } from '../form';
import { useShowcase } from '../hooks';
import { FORM_STEPS } from 'utils';
import resources from 'utils/resources';

import styles from './styles.module.scss';

const { Title } = Typography;

export const ShowcaseEdit: React.FC<IResourceComponentsProps> = () => {
    const {
        current,
        gotoStep,
        stepsProps,
        formProps,
        saveButtonProps,
        submit,
        form,
        onFinish,
        formLoading,
        queryResult
    } = useStepsForm<Showcase>({
        submit: async (formValues: any) => {
            const data = await formatPayloadShowcase(formValues, queryResult?.data?.data);
            return onFinish(data);
        },
        total: FORM_STEPS.length,
        redirect: false,
        successNotification: () => ({
            message: '',
            description: 'Save draft success',
            type: 'success'
        })
    });

    const { list } = useNavigation();

    const {
        industrySelectProps,
        serviceSelectProps,
        tagSelectProps,
        expertiseSelectProps,
        deliverableSelectProps,

        productTagsSelected,
        totalEstimation,
        scopes,
        statisticalESData,

        handleCreateTag,
        handleCreateIndustry,
        handleCreateDeliverable,
        handleSelectAllProductExpertiseScope,

        syncExpectedGoal,
        syncExpertiseScopeData,
        addMoreExpertiseScope,
        removeExpertiseScope,

        formatPayloadShowcase,
        formatDataInitialValue
    } = useShowcase(form);

    const showcaseData: Partial<Showcase> = queryResult?.data?.data || {};
    const formInitialValue = formatDataInitialValue(showcaseData);

    const [modal, contextHolder] = Modal.useModal();
    const remindSaveDraft = (step: number) =>
        modal.confirm({
            icon: <></>,
            title: <Title level={4}>Do you want to save before moving to other step?</Title>,
            content: "Input data will be lost if you don't save them.",
            okText: 'Save',
            onOk: async () => {
                await submit();
                gotoStep(step);
            },
            cancelText: 'Don’t save',
            onCancel: (close) => {
                gotoStep(step);
                return close();
            }
        });

    const handleSaveDraft = async () => {
        await submit();
        return list(resources.showcase);
    };

    const handleClickContinueBtn = async () => {
        if (current + 1 === formList.length) {
            await submit();
            return list(resources.showcase);
        }
        return remindSaveDraft(current + 1);
    };

    const { fileUploads, thumbnail, expertiseScopes } = formInitialValue;

    const formList = [
        <Initiating
            key={1}
            industrySelectProps={industrySelectProps}
            serviceSelectProps={serviceSelectProps}
            tagSelectProps={tagSelectProps}
            expertiseSelectProps={expertiseSelectProps}
            productTagsSelected={productTagsSelected}
            handleCreateIndustry={handleCreateIndustry}
            handleCreateTag={handleCreateTag}
            defaultValueFileUploads={fileUploads}
            defaultValueThumbnail={thumbnail}
            handleSelectAllExpertiseScope={handleSelectAllProductExpertiseScope}
        />,
        <Planning
            key={2}
            deliverableSelectProps={deliverableSelectProps}
            handleCreateDeliverable={handleCreateDeliverable}
            expertiseScopeSelected={scopes}
            totalEstimation={totalEstimation}
        />,
        <Delivery
            key={3}
            expertiseScopeSelected={scopes}
            expertiseSelectProps={expertiseSelectProps}
            initialValues={expertiseScopes}
            handleAddMoreExpertiseScope={addMoreExpertiseScope}
            handleRemoveExpertiseScope={removeExpertiseScope}
        />,
        <EvaluatingAndClosing key={4} statisticalESData={statisticalESData} />
    ];

    useEffect(() => {
        syncExpectedGoal(current);
        syncExpertiseScopeData(current);
    }, [current]);

    return (
        <Edit
            title="EDIT SHOWCASE"
            isLoading={queryResult?.isLoading || formLoading}
            headerButtons={() => null}
            footerButtons={() => (
                <>
                    <Button
                        className={styles.footerButtons}
                        {...saveButtonProps}
                        style={{ marginRight: 10 }}
                        onClick={handleSaveDraft}
                        loading={formLoading}>
                        Save draft
                    </Button>

                    <Button
                        type="primary"
                        className={styles.footerButtons}
                        loading={formLoading}
                        onClick={handleClickContinueBtn}>
                        {current + 1 === formList.length ? 'Completed' : 'Continue'}
                    </Button>
                </>
            )}
            footerButtonProps={{ style: { float: 'none' } }}>
            <div className={styles.createShowcaseContainer}>
                <Steps
                    {...stepsProps}
                    className={styles.showcaseStep}
                    items={FORM_STEPS.map((item) => ({
                        title: item.title
                    }))}
                    onChange={(step) => remindSaveDraft(step)}
                />
                <Form {...formProps} layout="vertical" initialValues={formInitialValue}>
                    {formList[current]}
                </Form>
            </div>
            <div>{contextHolder}</div>
        </Edit>
    );
};
