import React from 'react';
import { UseSelectReturnType } from '@pankod/refine-core';
import { Row, Col, Form, Input, Typography, Table } from '@pankod/refine-antd';
import { toString } from 'lodash';

import CustomSelect from 'components/CustomSelect';
import { CustomRangePicker } from 'components/CustomRangePicker';
import { planningPhase, expertiseScope } from 'pages/showcase/schema';
import EstimationInputNumber from 'components/EstimationInputNumber';
import { ExpertiseScope } from 'interfaces';

import styles from './styles.module.scss';

type PlanningProps = {
    deliverableSelectProps: UseSelectReturnType;
    handleCreateDeliverable: (value: string | number) => void;
    expertiseScopeSelected: ExpertiseScope[];
    totalEstimation?: any;
};

const { Title, Text } = Typography;
const { TextArea } = Input;

const {
    label: programLabel,
    name: programName,
    programExpectedGoal,
    programSpecificCondition,
    deliverable,
    timeline
} = planningPhase.program;

const { scope, cost, complexity, combination } = expertiseScope;

const columns = [
    {
        title: ' ',
        dataIndex: 'expertiseScope',
        render: (_: string, { expertiseScope }: ExpertiseScope) => (
            <Text>{expertiseScope?.name}</Text>
        )
    },
    {
        title: <Text className={styles.numberColumnTitle}>Scope (point)</Text>,
        dataIndex: 'estimationScope',
        render: (_: string, { expertiseScopeId }: ExpertiseScope) => (
            <Form.Item
                name={[toString(expertiseScopeId), scope.plan.name]}
                className={styles.inputNumberContainer}>
                <EstimationInputNumber />
            </Form.Item>
        )
    },
    {
        title: <Text className={styles.numberColumnTitle}>Cost (hour)</Text>,
        dataIndex: 'estimationCost',
        render: (_: string, { expertiseScopeId }: ExpertiseScope) => (
            <Form.Item
                name={[toString(expertiseScopeId), cost.plan.name]}
                className={styles.inputNumberContainer}>
                <EstimationInputNumber />
            </Form.Item>
        )
    },
    {
        title: <Text className={styles.numberColumnTitle}>Complexity</Text>,
        dataIndex: 'planComplexity',
        render: (_: string, { expertiseScopeId }: ExpertiseScope) => (
            <Form.Item
                name={[toString(expertiseScopeId), complexity.plan.name]}
                className={styles.inputNumberContainer}>
                <EstimationInputNumber />
            </Form.Item>
        )
    },
    {
        title: combination.label,
        children: [
            {
                title: <Text className={styles.numberColumnTitle}>Senior</Text>,
                dataIndex: 'senior',
                render: (_: string, { expertiseScopeId }: ExpertiseScope) => (
                    <Form.Item
                        name={[toString(expertiseScopeId), combination.plan.senior.name]}
                        className={styles.inputNumberContainer}>
                        <EstimationInputNumber addonAfter="%" />
                    </Form.Item>
                )
            },
            {
                title: <Text className={styles.numberColumnTitle}>Middle</Text>,
                dataIndex: 'middle',
                render: (_: string, { expertiseScopeId }: ExpertiseScope) => (
                    <Form.Item
                        name={[toString(expertiseScopeId), combination.plan.middle.name]}
                        className={styles.inputNumberContainer}>
                        <EstimationInputNumber addonAfter="%" />
                    </Form.Item>
                )
            },
            {
                title: <Text className={styles.numberColumnTitle}>Junior</Text>,
                dataIndex: 'junior',
                render: (_: string, { expertiseScopeId }: ExpertiseScope) => (
                    <Form.Item
                        name={[toString(expertiseScopeId), combination.plan.junior.name]}
                        className={styles.inputNumberContainer}>
                        <EstimationInputNumber addonAfter="%" />
                    </Form.Item>
                )
            }
        ]
    }
];

export default function Planning({
    deliverableSelectProps,
    handleCreateDeliverable,
    expertiseScopeSelected,
    totalEstimation
}: PlanningProps) {
    const renderTotalRow = () => {
        const { estimateTotalScope, estimateTotalCost } = totalEstimation;

        return (
            <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                    <Text className={styles.totalRowText}>{estimateTotalScope || 0}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                    <Text className={styles.totalRowText}>{estimateTotalCost || 0}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                    <Text className={styles.totalRowText}>N/A</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                    <Text className={styles.totalRowText}>N/A</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5}>
                    <Text className={styles.totalRowText}>N/A</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                    <Text className={styles.totalRowText}>N/A</Text>
                </Table.Summary.Cell>
            </Table.Summary.Row>
        );
    };

    return (
        <>
            <div>
                <Title level={4}>{programLabel}</Title>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label={programExpectedGoal.label}
                            name={[programName, programExpectedGoal.name]}>
                            <TextArea
                                placeholder={programExpectedGoal.placeholder}
                                autoSize
                                size="large"
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Form.Item
                            label={programSpecificCondition.label}
                            name={[programName, programSpecificCondition.name]}
                            tooltip={programSpecificCondition.tooltip}>
                            <TextArea
                                placeholder={programSpecificCondition.placeholder}
                                autoSize
                                size="large"
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Form.Item label={deliverable.label} name={[programName, deliverable.name]}>
                            <CustomSelect
                                mode="tags"
                                placeholder={deliverable.placeholder}
                                options={deliverableSelectProps.options}
                                handleCreateNewData={handleCreateDeliverable}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Form.Item label={timeline.label} name={[programName, timeline.name]}>
                            <CustomRangePicker />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Text className={styles.labelTable}>{planningPhase.estimation.label}</Text>
                    <Col span={24}>
                        <Form.List name={[planningPhase.estimation.name]}>
                            {(fields) => (
                                <Table
                                    className={styles.statisticTable}
                                    dataSource={expertiseScopeSelected}
                                    columns={columns}
                                    bordered
                                    rowKey="expertiseScopeId"
                                    pagination={{ position: [] }}
                                    summary={renderTotalRow}
                                />
                            )}
                        </Form.List>
                    </Col>
                </Row>
            </div>
        </>
    );
}
