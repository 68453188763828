import defaults from './defaults';

const colorPrimary = defaults.color['teal-5'];

const token = {
    colorPrimary,
    colorBgHeader: defaults.color['teal-5'],
    fontFamily: 'SF Pro Display, sans-serif'
};

const components = {
    Menu: {
        colorItemBg: colorPrimary
    },
    Tags: {
        fontSize: '14px'
    },
    Form: {
        colorTextLabel: defaults.color['neutral-6'],
        colorTextDescription: defaults.color['neutral-6'],
        colorFillContent: defaults.color['neutral-6']
    },
    Layout: {
        colorBgHeader: 'transparent'
    }
};

const spacing = {
    ...defaults.spacing
};

export { token, components, spacing };
