import { AuthProvider } from '@pankod/refine-core';
import jwtDecode from 'jwt-decode';

import { axiosInstance } from './utils';

import { TOKEN_NAME } from 'utils/constants';
import { getToken, setToken, removeToken } from 'utils/functions';

export const authProvider: AuthProvider = {
    login: ({ token }) => {
        if (!token) return Promise.reject();

        setToken(TOKEN_NAME, token);
        return Promise.resolve();
    },
    logout: () => {
        removeToken(TOKEN_NAME);
        return Promise.resolve('/');
    },
    checkError: () => Promise.resolve(),
    checkAuth: async () => {
        if (!getToken(TOKEN_NAME)) {
            return Promise.reject();
        }

        axiosInstance.defaults.headers.common = {
            Authorization: `Bearer ${getToken(TOKEN_NAME)}`
        };

        return Promise.resolve('/');
    },
    getPermissions: () => Promise.resolve(),
    getUserIdentity: () => {
        const token = localStorage.getItem(TOKEN_NAME);
        if (!token) {
            return Promise.reject();
        }

        const user = jwtDecode(token);
        return Promise.resolve(user);
    }
};
