import { DatePicker } from '@pankod/refine-antd';
import type { TimeRangePickerProps } from 'antd';
import dayjs from 'dayjs';

import { DATE_INPUT_FORMAT } from 'utils';

import styles from './styles.module.scss';

type CustomRangePickerProps = TimeRangePickerProps;

const { RangePicker } = DatePicker;

const CustomRangePicker = ({ ...props }: CustomRangePickerProps) => {
    return (
        <RangePicker
            format={DATE_INPUT_FORMAT}
            size="large"
            className={styles.datePickerContainer}
            {...props}
        />
    );
};

export { CustomRangePicker };
