import React from 'react';
import { Row, Col, Form, Input, Typography, Radio, Checkbox } from '@pankod/refine-antd';
import { UseSelectReturnType } from '@pankod/refine-core';
import { UploadFile } from '@pankod/refine-antd';

import type { ProductTagCheckbox } from 'interfaces';
import CustomSelect from 'components/CustomSelect';
import CustomDragger from 'components/CustomDragger';

import { initiatingPhase, validateThumbnailFile, expertiseScope } from 'pages/showcase/schema';

import resources from 'utils/resources';

import styles from './styles.module.scss';

type InitiatingProps = {
    industrySelectProps: UseSelectReturnType;
    serviceSelectProps: UseSelectReturnType;
    tagSelectProps: UseSelectReturnType;
    expertiseSelectProps: UseSelectReturnType;
    productTagsSelected?: ProductTagCheckbox[];
    defaultValueFileUploads?: UploadFile[];
    defaultValueThumbnail?: UploadFile[];
    handleCreateTag: (value: string | number) => void;
    handleCreateIndustry: (value: string | number) => void;
    handleSelectAllExpertiseScope?: (value: string | number) => void;
};

const { Title } = Typography;
const { TextArea } = Input;

export default function Initiating({
    industrySelectProps,
    serviceSelectProps,
    tagSelectProps,
    expertiseSelectProps,
    productTagsSelected,
    handleCreateTag,
    handleCreateIndustry,
    handleSelectAllExpertiseScope,
    defaultValueFileUploads,
    defaultValueThumbnail
}: InitiatingProps) {
    const { businessPartner, partnership, product } = initiatingPhase;
    const {
        label: businessPartnerLabel,
        name: businessPartnerName,
        brandName,
        industry,
        businessNature,
        type,
        context,
        businessExpectedGoal,
        problem
    } = businessPartner;
    const {
        label: partnershipModelLabel,
        name: partnershipName,
        partnershipModel,
        servicePackages
    } = partnership;
    const {
        label: productLabel,
        name: productName,
        tags,
        productExpertiseScopes,
        fileUpload,
        thumbnail,
        productMaturity
    } = product;

    return (
        <>
            <div>
                <Title level={4}>{businessPartnerLabel}</Title>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label={brandName.label}
                            name={[businessPartnerName, brandName.name]}
                            rules={brandName.rules}>
                            <Input placeholder={brandName.placeholder} size="large" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={industry.label}
                            name={[businessPartnerName, industry.name]}
                            rules={industry.rules}>
                            <CustomSelect
                                resource={resources.industry}
                                mode="tags"
                                placeholder={industry.placeholder}
                                options={industrySelectProps.options}
                                handleCreateNewData={handleCreateIndustry}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label={businessNature.label}
                            name={[businessPartnerName, businessNature.name]}>
                            <Radio.Group>
                                {businessNature.options.map(
                                    (item: { value: React.Key | null | string; label: string }) => (
                                        <Radio key={item.value} value={item.value}>
                                            {item.label}
                                        </Radio>
                                    )
                                )}
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={type.label} name={[businessPartnerName, type.name]}>
                            <Radio.Group>
                                {type.options.map(
                                    (item: { value: React.Key | null | string; label: string }) => (
                                        <Radio key={item.value} value={item.value}>
                                            {item.label}
                                        </Radio>
                                    )
                                )}
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Form.Item
                            label={context.label}
                            name={[businessPartnerName, context.name]}
                            tooltip={context.tooltip}>
                            <TextArea placeholder={context.placeholder} autoSize size="large" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label={problem.label}
                            name={[businessPartnerName, problem.name]}
                            tooltip={problem.tooltip}>
                            <TextArea placeholder={problem.placeholder} autoSize size="large" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label={businessExpectedGoal.label}
                            name={[businessPartnerName, businessExpectedGoal.name]}
                            tooltip={businessExpectedGoal.tooltip}>
                            <TextArea
                                placeholder={businessExpectedGoal.placeholder}
                                autoSize
                                size="large"
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </div>

            <div>
                <Title level={4}>{partnershipModelLabel}</Title>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label={partnershipModel.label}
                            name={[partnershipName, partnershipModel.name]}>
                            <Radio.Group>
                                {partnershipModel.options.map(
                                    (item: { value: React.Key | null | string; label: string }) => (
                                        <Radio key={item.value} value={item.value}>
                                            {item.label}
                                        </Radio>
                                    )
                                )}
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Form.Item
                            label={servicePackages.label}
                            name={[partnershipName, servicePackages.name]}>
                            <CustomSelect
                                placeholder={servicePackages.placeholder}
                                options={serviceSelectProps.options}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </div>

            <div>
                <Title level={4}>{productLabel}</Title>
                <Row>
                    <Col span={24}>
                        <Form.Item label={tags.label} name={[productName, tags.name]}>
                            <CustomSelect
                                placeholder={tags.placeholder}
                                options={tagSelectProps.options}
                                mode="tags"
                                handleCreateNewData={handleCreateTag}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    label={tags.subField}
                    name={[productName, tags.subFieldName]}
                    className={styles.appearTagContainer}>
                    <Checkbox.Group
                        className={styles.appearTagList}
                        options={productTagsSelected || []}></Checkbox.Group>
                </Form.Item>

                <Row>
                    <Col span={24}>
                        <Form.Item
                            label={productMaturity.label}
                            name={[productName, productMaturity.name]}>
                            <Radio.Group>
                                {productMaturity.options.map(
                                    (item: { value: React.Key | null | string; label: string }) => (
                                        <Radio key={item.value} value={item.value}>
                                            {item.label}
                                        </Radio>
                                    )
                                )}
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Form.Item
                            label={productExpertiseScopes.label}
                            name={[productName, productExpertiseScopes.name]}>
                            <CustomSelect
                                placeholder={productExpertiseScopes.placeholder}
                                options={expertiseSelectProps.options}
                                allowSelectAll
                                handleSelectAll={handleSelectAllExpertiseScope}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Form.Item label={fileUpload.label} name={fileUpload.name}>
                            <CustomDragger
                                placeholder={fileUpload.note}
                                accept={fileUpload.accept}
                                multiple
                                defaultFileList={defaultValueFileUploads}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Form.Item
                            label={thumbnail.label + thumbnail.subLabel.suggest}
                            name={thumbnail.name}>
                            <CustomDragger
                                listType="picture"
                                placeholder={thumbnail.note}
                                accept={thumbnail.accept}
                                maxCount={thumbnail.limit}
                                beforeUpload={validateThumbnailFile}
                                multiple
                                defaultFileList={defaultValueThumbnail}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        </>
    );
}
