const Publish = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 1.334a6.67 6.67 0 0 0-6.667 6.667A6.669 6.669 0 0 0 8 14.667a6.669 6.669 0 0 0 6.666-6.666A6.67 6.67 0 0 0 8 1.334Zm-.667 12.333c-2.633-.326-5-2.946-5-5.666 0-.414 0-1 .333-1.334L6 10.001v.666c0 .734.6 1.334 1.333 1.334v1.666Zm4.667-2c-.174-.54-.734-1-1.334-1H10v-2c0-.366-.3-.666-.667-.666h-4V6.667h1.333c.367 0 .667-.3.667-.666V4.667h1.333c.734 0 1.334-.6 1.334-1.333v-.667c1.953.794 3.666 3.1 3.666 5.334 0 1.386-.8 2.72-1.666 3.666Z"
      fill="#000D0B"
    />
  </svg>
);
export default Publish;
