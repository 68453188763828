// Define resources of system

export default {
    showcase: 'showcase',
    industry: 'industry',
    servicePackage: 'service-package',
    tag: 'product-tag',
    expertiseScope: 'expertise-scope',
    deliverable: 'deliverable',
    showcaseSummary: 'showcase/summary'
};
