import React from 'react';
import { Upload } from '@pankod/refine-antd';
import { Export } from 'components/Icons';
import type { UploadProps } from '@pankod/refine-antd';

import { Remove } from 'components/Icons';
import { getToken, TOKEN_NAME } from 'utils';

import styles from './styles.module.scss';

type CustomDraggerProps = UploadProps & {
    value?: string | number;
    placeholder?: string;
};

export default function CustomDragger({
    placeholder,
    beforeUpload = () => false,
    ...rest
}: CustomDraggerProps) {
    const dummyRequest = ({ onSuccess }: any) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };
    return (
        <Upload.Dragger
            className={styles.draggerContainer}
            beforeUpload={beforeUpload}
            headers={{ Authorization: `Bearer ${getToken(TOKEN_NAME)}` }}
            customRequest={dummyRequest}
            showUploadList={{ removeIcon: <Remove /> }}
            {...rest}>
            <Export />
            <p>Click or drag file to this area to upload</p>
            <span>{placeholder}</span>
        </Upload.Dragger>
    );
}
