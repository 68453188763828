import * as React from "react";
const GarbageCan = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.625 2.875H5.5a.125.125 0 0 0 .125-.125v.125h4.75V2.75c0 .069.056.125.125.125h-.125V4H11.5V2.75a1 1 0 0 0-1-1h-5a1 1 0 0 0-1 1V4h1.125V2.875ZM13.5 4h-11a.5.5 0 0 0-.5.5V5c0 .069.056.125.125.125h.944l.386 8.172a1 1 0 0 0 .998.953h7.094a.999.999 0 0 0 .998-.953l.386-8.172h.944A.125.125 0 0 0 14 5v-.5a.5.5 0 0 0-.5-.5Zm-2.073 9.125H4.573l-.378-8h7.61l-.378 8Z"
      fill="#000D0B"
    />
  </svg>
);
export default GarbageCan;