import React, { useEffect } from 'react';
import { IResourceComponentsProps, useNavigation, useNotification } from '@pankod/refine-core';
import { Create, Form, Steps, useStepsForm, Button, Modal, Typography } from '@pankod/refine-antd';

import { Showcase } from 'interfaces';

import { Initiating, Planning, Delivery, EvaluatingAndClosing } from '../form';
import { useShowcase } from '../hooks';
import { FORM_STEPS, UNKNOWN_ERR } from 'utils';
import resources from 'utils/resources';

import styles from './styles.module.scss';

const { Title } = Typography;

export const ShowcaseCreate: React.FC<IResourceComponentsProps> = () => {
    const {
        current,
        gotoStep,
        stepsProps,
        formProps,
        saveButtonProps,
        submit,
        form,
        onFinish,
        formLoading
    } = useStepsForm<Showcase>({
        submit: async (formValues: any) => {
            const data = await formatPayloadShowcase(formValues);
            return onFinish(data);
        },
        total: FORM_STEPS.length,
        redirect: false,
        successNotification: () => ({
            message: '',
            description: 'Save draft success',
            type: 'success'
        })
    });

    const { list, edit } = useNavigation();
    const { open } = useNotification();

    const {
        industrySelectProps,
        serviceSelectProps,
        tagSelectProps,
        expertiseSelectProps,
        deliverableSelectProps,

        scopes,
        productTagsSelected,
        totalEstimation,
        statisticalESData,

        handleCreateTag,
        handleCreateIndustry,
        handleCreateDeliverable,
        handleSelectAllProductExpertiseScope,

        syncExpectedGoal,
        syncExpertiseScopeData,
        addMoreExpertiseScope,
        removeExpertiseScope,

        formatPayloadShowcase
    } = useShowcase(form);

    const [modal, contextHolder] = Modal.useModal();

    const handleSaveDraft = async () => {
        await submit();
        return list(resources.showcase);
    };

    const handleCreateNewShowcase = async () => {
        try {
            const res: any = await submit();
            const showcaseId = res?.data?.id;
            return edit(resources.showcase, showcaseId);
        } catch (error) {
            open?.(UNKNOWN_ERR);
        }
        return list(resources.showcase);
    };

    const remindSaveDraft = (step: number) =>
        modal.confirm({
            icon: <></>,
            title: <Title level={4}>Do you want to save before moving to other step?</Title>,
            content: "Input data will be lost if you don't save them.",
            okText: 'Save',
            onOk: async () => {
                await handleCreateNewShowcase();
            },
            cancelText: 'Don’t save',
            onCancel: (close) => {
                gotoStep(step);
                return close();
            }
        });

    const handleClickContinueBtn = async () => {
        if (current + 1 === formList.length) {
            await submit();
            return list(resources.showcase);
        }
        return remindSaveDraft(current + 1);
    };

    const formList = [
        <Initiating
            key={1}
            industrySelectProps={industrySelectProps}
            serviceSelectProps={serviceSelectProps}
            tagSelectProps={tagSelectProps}
            expertiseSelectProps={expertiseSelectProps}
            productTagsSelected={productTagsSelected}
            handleCreateIndustry={handleCreateIndustry}
            handleCreateTag={handleCreateTag}
            handleSelectAllExpertiseScope={handleSelectAllProductExpertiseScope}
        />,
        <Planning
            key={2}
            deliverableSelectProps={deliverableSelectProps}
            handleCreateDeliverable={handleCreateDeliverable}
            expertiseScopeSelected={scopes}
            totalEstimation={totalEstimation}
        />,
        <Delivery
            key={3}
            expertiseScopeSelected={scopes}
            expertiseSelectProps={expertiseSelectProps}
            handleAddMoreExpertiseScope={addMoreExpertiseScope}
            handleRemoveExpertiseScope={removeExpertiseScope}
        />,
        <EvaluatingAndClosing key={4} statisticalESData={statisticalESData} />
    ];

    useEffect(() => {
        syncExpectedGoal(current);
        syncExpertiseScopeData(current);
    }, [current]);

    return (
        <Create
            title="CREATE NEW SHOWCASE"
            footerButtons={() => (
                <>
                    <Button
                        className={styles.footerButtons}
                        {...saveButtonProps}
                        style={{ marginRight: 10 }}
                        onClick={handleSaveDraft}
                        loading={formLoading}>
                        Save draft
                    </Button>

                    <Button
                        type="primary"
                        className={styles.footerButtons}
                        onClick={handleClickContinueBtn}>
                        {current + 1 === formList.length ? 'Completed' : 'Continue'}
                    </Button>
                </>
            )}
            footerButtonProps={{ style: { float: 'none' } }}>
            <div className={styles.createShowcaseContainer}>
                <Steps
                    {...stepsProps}
                    className={styles.showcaseStep}
                    items={FORM_STEPS.map((item) => ({
                        title: item.title
                    }))}
                    onChange={(step) => remindSaveDraft(step)}
                />
                <Form {...formProps} layout="vertical">
                    {formList[current]}
                </Form>
            </div>
            <div>{contextHolder}</div>
        </Create>
    );
};
