import * as React from 'react';
const UnPublish = (props) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
            d="M7.74 4.667a.323.323 0 0 1-.551.229L5.597 3.304c-.447-.447-.372-1.195.21-1.442A6.67 6.67 0 0 1 15.073 8a6.607 6.607 0 0 1-.814 3.189.52.52 0 0 1-.758.16c-.269-.187-.315-.566-.155-.852.425-.757.62-1.73.62-2.497 0-2.234-1.607-4.207-3.56-5v.333c0 .733-.6 1.333-1.334 1.333H7.74Zm6.323 8.99a.665.665 0 1 1-.94.94l-1.043-1.043a6.56 6.56 0 0 1-3.673 1.113A6.67 6.67 0 0 1 1.74 8.001c0-1.36.407-2.62 1.107-3.674L1.804 3.284a.665.665 0 1 1 .94-.94L8.404 8l5.66 5.656ZM7.74 12.001c-.733 0-1.333-.6-1.333-1.334v-.666L3.074 6.667c-.227.344-.227.92-.227 1.334 0 2.72 2.26 5.34 4.893 5.666v-1.666Z"
            fill="#000D0B"
        />
    </svg>
);
export default UnPublish;
