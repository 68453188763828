import {
    List,
    Table,
    TextField,
    Tabs,
    CreateButton,
    EditButton,
    DeleteButton,
    Button,
    Form,
    Input,
    Typography,
    ConfigProvider,
    Tooltip,
    Modal
} from '@pankod/refine-antd';
import { debounce, isArray } from 'lodash';

import { Showcase, ExpertiseScope } from 'interfaces';
import CustomTagField from 'components/CustomTag';
import { Duplicate, Publish, UnPublish, SearchIcon } from 'components/Icons';
import { INPUT_STATUS_TABS, INPUT_STATUS_NAME, STATUS_VALUE } from 'utils';

import { useShowcaseList } from './hooks';

import styles from './styles.module.scss';

type ColumnFilterItem = {
    text: React.ReactNode;
    value: string | number | boolean;
    children?: ColumnFilterItem[];
};

const { Text, Title } = Typography;

export const ShowcaseList: React.FC = () => {
    const {
        tableProps,
        filters,
        expertiseScopes,
        searchFormProps,
        createLoading,
        showcaseEnum,

        handleChangeTab,
        handleDuplicateShowcase,
        handleTogglePublishShowcase
    } = useShowcaseList();

    const [modal, contextHolder] = Modal.useModal();

    const pagination: any = tableProps.pagination;
    const expertiseScopeFilter: ColumnFilterItem[] = (expertiseScopes.data?.data || []).map(
        (item: any) => ({
            text: item.name,
            value: item.id
        })
    );

    const inputStatusFilter: any = INPUT_STATUS_TABS.map((item: any) => ({
        text: item.label,
        value: item.name
    }));

    const renderEmptyShowcase: any = () => {
        if ((!filters || !filters.length) && pagination.total) {
            return (
                <div className={styles.emptyShowcaseContainer}>
                    <CreateButton type="primary" icon={null}>
                        Create new showcase
                    </CreateButton>
                </div>
            );
        }

        return (
            <div className={styles.emptyShowcaseContainer}>
                <Title level={5} className={styles.titleEmpty}>
                    Sorry, no showcase found
                </Title>
                <Text>Try adjusting your search or filter to find what you’re looking for</Text>
            </div>
        );
    };

    const confirmPublishShowcase = (id: number | string | undefined) => {
        modal.confirm({
            title: 'Do you want to publish this showcase?',
            content: 'Publish will allow people to share this showcase with business partner',
            icon: <></>,
            okText: 'Publish',
            cancelText: 'Cancel',
            onOk: () => handleTogglePublishShowcase(id, 'publish')
        });
    };

    const confirmUnPublishShowcase = (id: number | string | undefined) => {
        modal.confirm({
            title: 'Do you want to unpublish this showcase?',
            content: 'Unpublish could prevent business partner from seeing this showcase.',
            icon: <></>,
            okText: 'Unpublish',
            cancelText: 'Cancel',
            onOk: () => handleTogglePublishShowcase(id, 'unpublish')
        });
    };

    return (
        <List
            title="Product library"
            headerButtons={
                <CreateButton type="primary" icon={null} size="large">
                    Create new showcase
                </CreateButton>
            }>
            <Tabs
                onChange={handleChangeTab}
                type="card"
                size="large"
                className={styles.tabContainer}
                items={INPUT_STATUS_TABS.map((item) => ({
                    label: `${item.label} (${showcaseEnum[item.name] || 0})`,
                    key: item.name,
                    children: (
                        <ConfigProvider renderEmpty={renderEmptyShowcase}>
                            {isArray(filters) && filters.length ? (
                                <div className={styles.resultQueryContainer}>
                                    <Text className={styles.resultQuery}>
                                        {pagination?.total || 0} showcase found
                                    </Text>
                                </div>
                            ) : null}
                            <Table
                                {...tableProps}
                                loading={tableProps.loading || createLoading}
                                rowKey="id">
                                <Table.Column
                                    dataIndex="businessPartner"
                                    title="Brand Name"
                                    width={250}
                                    sorter={true}
                                    render={(value) => (
                                        <TextField strong value={value.brandName || ''} />
                                    )}
                                />
                                <Table.Column
                                    dataIndex="expertiseScopes"
                                    width={600}
                                    title="Expertise Scope"
                                    filters={expertiseScopeFilter}
                                    render={(values, record: Showcase) => (
                                        <div className={styles.tableCellFlex}>
                                            {(values || []).map((value: ExpertiseScope) => (
                                                <CustomTagField
                                                    key={`${value.id}${record.id}`}
                                                    value={value?.expertiseScope?.name}
                                                />
                                            ))}
                                        </div>
                                    )}
                                />
                                <Table.Column
                                    dataIndex=""
                                    width={394}
                                    title="Input status"
                                    // filters={inputStatusFilter}
                                    render={(_, record: Showcase) => {
                                        const {
                                            initiatingStatus,
                                            planningStatus,
                                            deliveryStatus,
                                            evaluationStatus,
                                            published
                                        } = record;

                                        if (item.name === INPUT_STATUS_NAME.initiatingStatus.name) {
                                            return (
                                                <CustomTagField
                                                    color={INPUT_STATUS_NAME.initiatingStatus.color}
                                                    value={INPUT_STATUS_NAME.initiatingStatus.label}
                                                />
                                            );
                                        }

                                        if (item.name === INPUT_STATUS_NAME.planningStatus.name) {
                                            return (
                                                <CustomTagField
                                                    color={INPUT_STATUS_NAME.planningStatus.color}
                                                    value={INPUT_STATUS_NAME.planningStatus.label}
                                                />
                                            );
                                        }

                                        if (item.name === INPUT_STATUS_NAME.deliveryStatus.name) {
                                            return (
                                                <CustomTagField
                                                    color={INPUT_STATUS_NAME.deliveryStatus.color}
                                                    value={INPUT_STATUS_NAME.deliveryStatus.label}
                                                />
                                            );
                                        }

                                        if (item.name === INPUT_STATUS_NAME.evaluationStatus.name) {
                                            return (
                                                <CustomTagField
                                                    color={INPUT_STATUS_NAME.evaluationStatus.color}
                                                    value={INPUT_STATUS_NAME.evaluationStatus.label}
                                                />
                                            );
                                        }

                                        if (published) {
                                            return (
                                                <CustomTagField
                                                    color={INPUT_STATUS_NAME.published.color}
                                                    value={INPUT_STATUS_NAME.published.label}
                                                />
                                            );
                                        }

                                        if (
                                            initiatingStatus === STATUS_VALUE.done &&
                                            planningStatus === STATUS_VALUE.done &&
                                            deliveryStatus === STATUS_VALUE.done &&
                                            evaluationStatus === STATUS_VALUE.done
                                        ) {
                                            return (
                                                <CustomTagField
                                                    color={INPUT_STATUS_NAME.completed.color}
                                                    value={INPUT_STATUS_NAME.completed.label}
                                                />
                                            );
                                        }

                                        return (
                                            <div className={styles.tableCellFlex}>
                                                {initiatingStatus === STATUS_VALUE.inputting ? (
                                                    <CustomTagField
                                                        color={
                                                            INPUT_STATUS_NAME.initiatingStatus.color
                                                        }
                                                        value={
                                                            INPUT_STATUS_NAME.initiatingStatus.label
                                                        }
                                                    />
                                                ) : null}
                                                {planningStatus === STATUS_VALUE.inputting ? (
                                                    <CustomTagField
                                                        color={
                                                            INPUT_STATUS_NAME.planningStatus.color
                                                        }
                                                        value={
                                                            INPUT_STATUS_NAME.planningStatus.label
                                                        }
                                                    />
                                                ) : null}
                                                {deliveryStatus === STATUS_VALUE.inputting ? (
                                                    <CustomTagField
                                                        color={
                                                            INPUT_STATUS_NAME.deliveryStatus.color
                                                        }
                                                        value={
                                                            INPUT_STATUS_NAME.deliveryStatus.label
                                                        }
                                                    />
                                                ) : null}
                                                {evaluationStatus === STATUS_VALUE.inputting ? (
                                                    <CustomTagField
                                                        color={
                                                            INPUT_STATUS_NAME.evaluationStatus.color
                                                        }
                                                        value={
                                                            INPUT_STATUS_NAME.evaluationStatus.label
                                                        }
                                                    />
                                                ) : null}
                                            </div>
                                        );
                                    }}
                                />
                                <Table.Column
                                    dataIndex="published"
                                    title="Action"
                                    width={144}
                                    render={(
                                        published: boolean,
                                        {
                                            id,
                                            initiatingStatus,
                                            planningStatus,
                                            deliveryStatus,
                                            evaluationStatus
                                        }: Showcase
                                    ) => (
                                        <div className={styles.actionContainer}>
                                            <Tooltip title="Edit" placement="bottom">
                                                <EditButton
                                                    recordItemId={id}
                                                    hideText
                                                    type="text"
                                                />
                                            </Tooltip>

                                            <Tooltip title="Duplicate" placement="bottom">
                                                <Button
                                                    className={styles.actionBtn}
                                                    icon={<Duplicate />}
                                                    type="text"
                                                    onClick={() => handleDuplicateShowcase(id)}
                                                />
                                            </Tooltip>
                                            <Tooltip title="Delete" placement="bottom">
                                                <DeleteButton
                                                    recordItemId={id}
                                                    hideText
                                                    type="text"
                                                    danger={false}
                                                    mutationMode="optimistic"
                                                />
                                            </Tooltip>

                                            {initiatingStatus === STATUS_VALUE.done &&
                                                planningStatus === STATUS_VALUE.done &&
                                                deliveryStatus === STATUS_VALUE.done &&
                                                evaluationStatus === STATUS_VALUE.done &&
                                                (!published ? (
                                                    <Tooltip title="Publish" placement="bottom">
                                                        <Button
                                                            className={styles.actionBtn}
                                                            icon={<Publish />}
                                                            type="text"
                                                            onClick={() =>
                                                                confirmPublishShowcase(id)
                                                            }
                                                        />
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip title="Unpublish" placement="bottom">
                                                        <Button
                                                            className={styles.actionBtn}
                                                            icon={<UnPublish />}
                                                            type="text"
                                                            onClick={() =>
                                                                confirmUnPublishShowcase(id)
                                                            }
                                                        />
                                                    </Tooltip>
                                                ))}
                                        </div>
                                    )}
                                />
                            </Table>
                        </ConfigProvider>
                    )
                }))}
                tabBarExtraContent={{
                    right: (
                        <Form
                            className={styles.searchForm}
                            {...searchFormProps}
                            onValuesChange={debounce(() => searchFormProps.form?.submit(), 500)}>
                            <Form.Item name="searchValue">
                                <Input
                                    className={styles.searchInput}
                                    prefix={<SearchIcon />}
                                    placeholder="Search showcase"
                                    allowClear
                                />
                            </Form.Item>
                        </Form>
                    )
                }}
            />
            {contextHolder}
        </List>
    );
};
