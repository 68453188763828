import { useState } from 'react';
import { useSelect, useCreate } from '@pankod/refine-core';
import { FormInstance, Form } from '@pankod/refine-antd';

import dayjs from 'dayjs';
import { values, sumBy, toString, pick, forEach } from 'lodash';

import type {
    MasterData,
    ExpertiseScope,
    ProductTag,
    ProductTagCheckbox,
    StatisticData
} from 'interfaces';
import resources from 'utils/resources';
import { MS_CUSTOM_FILED, mapArrToObject, PLAN_FIELDS } from 'utils';
import {
    initiatingPhase,
    planningPhase,
    expertiseScope,
    evaluatingPhase
} from 'pages/showcase/schema';
import { handleUploadFile, formatValueEstimationTable } from './functions';

import { formatInitialValue, formatShowcase } from './format';

const { businessPartner, product } = initiatingPhase;
const { industry, businessExpectedGoal } = businessPartner;
const { tags, productExpertiseScopes } = product;

const { program } = planningPhase;
const { programExpectedGoal, deliverable } = program;

const { scope, cost } = expertiseScope;

const convertOptionsNameToId = (selectedValues: string[] | number[], data: MasterData) =>
    selectedValues.map((item: string | number) => {
        if (item === data?.name) {
            return data?.id;
        }
        return item;
    });

function useSelectMasterData(resource: string) {
    const masterDataSelectProps = useSelect<MasterData>({
        resource,
        hasPagination: false,
        queryOptions: {
            retry: 3
        },
        ...MS_CUSTOM_FILED
    });

    return masterDataSelectProps;
}

function useShowcase(form: FormInstance) {
    const { getFieldValue, setFieldValue } = form;

    // GET Master data
    const industrySelectProps = useSelectMasterData(resources.industry);
    const serviceSelectProps = useSelectMasterData(resources.servicePackage);
    const tagSelectProps = useSelectMasterData(resources.tag);
    const deliverableSelectProps = useSelectMasterData(resources.deliverable);

    const expertiseSelectProps = useSelectMasterData(resources.expertiseScope);
    const expertiseScopeData = (expertiseSelectProps?.queryResult?.data?.data || []).map(
        (item: any) => pick(item, ['id', 'name', 'fields'])
    );
    // Normalize expertise scope data
    const expertiseScopeDic = mapArrToObject(expertiseScopeData, 'id');

    // CREATE Master data
    const { mutate } = useCreate<MasterData>();

    const handleCreateIndustry = (value: string | number) =>
        mutate(
            {
                resource: resources.industry,
                values: {
                    name: value
                }
            },
            {
                onSuccess: ({ data }) => {
                    const currentValue = getFieldValue([businessPartner.name, industry.name]);
                    setFieldValue(
                        [businessPartner.name, industry.name],
                        convertOptionsNameToId(currentValue, data)
                    );
                }
            }
        );

    const handleCreateTag = (value: string | number) =>
        mutate(
            {
                resource: resources.tag,
                values: {
                    name: value,
                    appearShowcase: false
                }
            },
            {
                onSuccess: ({ data }) => {
                    const currentValue = getFieldValue([product.name, tags.name]);
                    setFieldValue(
                        [product.name, tags.name],
                        convertOptionsNameToId(currentValue, data)
                    );
                }
            }
        );

    const handleCreateDeliverable = (value: string | number) =>
        mutate(
            {
                resource: resources.deliverable,
                values: { name: value }
            },
            {
                onSuccess: ({ data }) => {
                    const currentValue = getFieldValue([program.name, deliverable.name]);
                    setFieldValue(
                        [program.name, deliverable.name],
                        convertOptionsNameToId(currentValue, data)
                    );
                }
            }
        );

    const handleSelectAllProductExpertiseScope = () => {
        setFieldValue(
            [product.name, expertiseScope.name],
            expertiseSelectProps.options.map((item) => item?.value)
        );
    };
    // FORM HANDLING

    // Expertise scope global state
    const [scopes, setScopes] = useState<ExpertiseScope[]>([]);

    // Handle Product Tag
    const productTagValues = Form.useWatch([product.name, tags.name], form);
    const productTagData = mapArrToObject(tagSelectProps.options, 'value');
    const productTagsSelected: ProductTagCheckbox[] = (productTagValues || []).map(
        (item: ProductTag) => {
            const id = item?.productTagId || item;
            return {
                value: id,
                label: productTagData[id.toString()]?.label,
                appearShowcase: item?.appearShowcase || false
            };
        }
    );

    // Estimation data planning phase
    const estimations = Form.useWatch([planningPhase.estimation.name], form);
    const estimationArr = values(estimations);
    const estimateTotalScope = sumBy(estimationArr, scope.plan.name);
    const estimateTotalCost = sumBy(estimationArr, cost.plan.name);

    // Scope cost statistic
    const scopeDelivery = getFieldValue([expertiseScope.name]);
    const scopeDeliveryArr = values(scopeDelivery);
    const statisticalESData: StatisticData[] = [
        {
            rowKey: 'plan',
            rowLabel: 'Plan',
            scope: sumBy(scopeDeliveryArr, (_it: any) => _it?.plan?.estimationScope),
            cost: sumBy(scopeDeliveryArr, (_it: any) => _it?.plan?.estimationCost)
        },
        {
            rowKey: 'actual',
            rowLabel: 'Actual',
            scope: sumBy(scopeDeliveryArr, (_it: any) => _it?.actual?.actualScope),
            cost: sumBy(scopeDeliveryArr, (_it: any) => _it?.actual?.actualCost)
        }
    ];

    const syncExpectedGoal = (step: number) => {
        const businessGoal = getFieldValue([businessPartner.name, businessExpectedGoal.name]);
        if (step === 1 && !getFieldValue([program.name, programExpectedGoal.name])) {
            setFieldValue([program.name, programExpectedGoal.name], businessGoal);
            return;
        }

        const programGoal = getFieldValue([program.name, programExpectedGoal.name]);
        if (
            step === 3 &&
            !getFieldValue([product.name, evaluatingPhase.product.expectedGoal.name])
        ) {
            setFieldValue([product.name, evaluatingPhase.product.expectedGoal.name], programGoal);
            return;
        }
    };

    const syncExpertiseScopeData = (step: number) => {
        // GET selected expertise scopes from phase 1
        const productESFields = getFieldValue([product.name, productExpertiseScopes.name]) || [];
        const selectedES = productESFields.map((item: ExpertiseScope) => {
            const expertiseScopeId = item?.expertiseScopeId || item;
            return {
                expertiseScopeId,
                expertiseScope: {
                    name: expertiseScopeDic[toString(expertiseScopeId)]?.name,
                    fields: expertiseScopeDic[toString(expertiseScopeId)]?.fields
                }
            };
        });

        // Update selected expertise scope global state
        setScopes(
            selectedES.map((item: ExpertiseScope) => {
                const expertiseScopeId = item?.expertiseScopeId || item;
                return {
                    expertiseScopeId,
                    expertiseScope: {
                        name: expertiseScopeDic[toString(expertiseScopeId)]?.name,
                        fields: expertiseScopeDic[toString(expertiseScopeId)]?.fields
                    }
                };
            })
        );

        if (step === 1 && getFieldValue([expertiseScope.name])) {
            const planData = formatValueEstimationTable(getFieldValue([expertiseScope.name]));
            setFieldValue([planningPhase.estimation.name], planData);
            return;
        }

        if (step === 2 && getFieldValue([planningPhase.estimation.name])) {
            const estimationData = getFieldValue([planningPhase.estimation.name]);
            forEach(estimationData, (value, key) => {
                setFieldValue([expertiseScope.name, key, 'plan'], pick(value, PLAN_FIELDS));
            });
            return;
        }
    };

    const addMoreExpertiseScope = (expertiseScopeId: string | number) => {
        const currentExpertiseScopes =
            getFieldValue([product.name, productExpertiseScopes.name]) || [];
        setFieldValue(
            [product.name, productExpertiseScopes.name],
            [...currentExpertiseScopes, expertiseScopeId]
        );

        setScopes([
            ...scopes,
            {
                expertiseScopeId,
                expertiseScope: {
                    name: expertiseScopeDic[toString(expertiseScopeId)]?.name,
                    fields: expertiseScopeDic[toString(expertiseScopeId)]?.fields
                }
            }
        ]);
    };

    const removeExpertiseScope = (expertiseScopeId: string | number) => {
        const currentProductES = getFieldValue([product.name, productExpertiseScopes.name]);
        setFieldValue(
            [product.name, productExpertiseScopes.name],
            [
                ...currentProductES.filter((item: ExpertiseScope) => {
                    const id = item?.expertiseScopeId || item;
                    return id !== expertiseScopeId;
                })
            ]
        );

        setScopes(
            scopes.filter((_it: ExpertiseScope) => _it?.expertiseScopeId !== expertiseScopeId)
        );
    };

    const formatDataInitialValue = (data: any) => formatInitialValue(data);

    const formatPayloadShowcase = async (formValues: any, initialValue: any = {}) =>
        formatShowcase(formValues, initialValue, estimateTotalScope, estimateTotalCost);

    return {
        industrySelectProps,
        serviceSelectProps,
        tagSelectProps,
        expertiseSelectProps,
        deliverableSelectProps,

        productTagsSelected,
        totalEstimation: {
            estimateTotalScope,
            estimateTotalCost
        },
        scopes,
        statisticalESData,

        handleCreateIndustry,
        handleCreateTag,
        handleCreateDeliverable,
        handleUploadFile,
        handleSelectAllProductExpertiseScope,

        syncExpectedGoal,
        syncExpertiseScopeData,
        addMoreExpertiseScope,
        removeExpertiseScope,

        formatPayloadShowcase,
        formatDataInitialValue
    };
}

export { useShowcase };
