import React from 'react';
import { Layout as AntdLayout, Typography, Avatar, Space } from 'antd';
import { useGetIdentity, useLogout } from '@pankod/refine-core';
import { RefineLayoutHeaderProps, Dropdown } from '@pankod/refine-antd';

import styles from './styles.module.scss';

const { Text } = Typography;

const UserIdentity: React.FC<RefineLayoutHeaderProps> = () => {
    const { data: user } = useGetIdentity();
    const { mutate: logout } = useLogout();

    const shouldRenderHeader = user && (user.name || user.avatar);

    return shouldRenderHeader ? (
        <AntdLayout.Header className={styles.userIdentityContainer}>
            <Space className={styles.userIdentity} align="center">
                {user.name && (
                    <Text ellipsis strong className={styles.name}>
                        {user.name}
                    </Text>
                )}
                <Dropdown
                    menu={{
                        items: [
                            {
                                key: '1',
                                label: 'Log out',
                                onClick: () => logout()
                            }
                        ]
                    }}>
                    {user.avatar && <Avatar size="large" src={user?.avatar} alt={user?.name} />}
                </Dropdown>
            </Space>
        </AntdLayout.Header>
    ) : null;
};

export default UserIdentity;
