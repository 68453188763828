import { useMenu } from '@pankod/refine-core';
import { Menu, Space } from '@pankod/refine-antd';
import routerProvider from '@pankod/refine-react-router-v6';

import styles from './styles.module.scss';

const { Link } = routerProvider;

const CustomSider: React.FC = () => {
    const { menuItems, selectedKey } = useMenu();

    const items: any[] = [
        {
            key: 'logo',
            label: (
                <Link to="/">
                    <img
                        src="/logo/atlas-1x.png"
                        srcSet="/logo/atlas-1x.png 1x,  /logo/atlas-2x.png 2x"
                    />
                </Link>
            )
        },
        ...menuItems.map(({ icon, route, label }) => ({
            key: route,
            label: <Link to={route || ''}>{label}</Link>,
            icon,
            route
        }))
    ];

    return (
        <>
            <Menu
                className={styles.menuContainer}
                selectedKeys={[selectedKey]}
                mode="horizontal"
                theme="dark"
                items={items}
            />
        </>
    );
};

export default CustomSider;
