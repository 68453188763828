export const UNKNOWN_ERR: {
    type: 'success' | 'error' | 'progress';
    message: string;
    description?: string;
    key?: string;
    cancelMutation?: () => void;
    undoableTimeout?: number;
} = {
    type: 'error',
    message: 'Error',
    description: 'Something went wrong'
};
