import * as React from 'react';
const Plus = (props) => (
    <svg
        width={14}
        height={14}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.42 7.537a.467.467 0 0 0 0-.933H7.578V1.646a.467.467 0 1 0-.933 0v4.958H1.686a.467.467 0 0 0 0 .933h4.959v4.842a.467.467 0 1 0 .933 0V7.537h4.842Z"
            fill="#33B3AA"
        />
    </svg>
);
export default Plus;
