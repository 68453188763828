import { Input, InputProps } from '@pankod/refine-antd';
import styles from './styles.module.scss';

type TextAreaProps = InputProps & any;

const { TextArea } = Input;

const CustomTextArea = ({ className, ...rest }: TextAreaProps) => (
    <TextArea className={`${styles.customTextArea} ${className}`} {...rest} />
);

export default CustomTextArea;
