import { UPLOAD } from 'configs/api';

const initiatingPhase = {
    businessPartner: {
        label: 'Business partner',
        name: 'businessPartner',
        brandName: {
            name: 'brandName',
            label: 'Brand name',
            placeholder: 'Input business partner’s Brand name',
            rules: [{ required: true, message: 'Please input Brand name' }]
            // rules: []
        },
        industry: {
            name: 'industries',
            label: 'Industry',
            placeholder: 'Select or create new industry',
            rules: [{ required: true, message: 'Please select at least one industry' }]
            // rules: []
        },
        businessNature: {
            name: 'businessNature',
            label: 'Business nature',
            options: [
                { label: 'Start-up', value: 'start-up' },
                { label: 'Corporation', value: 'corporation' }
            ]
        },
        type: {
            name: 'type',
            label: 'Type',
            options: [
                { label: 'Flexible scope', value: 'flexible-scope' },
                { label: 'Fixed scope', value: 'fixed-scope' }
            ]
        },
        context: {
            name: 'context',
            label: 'Brief/Context',
            tooltip: 'Why business partner come to GEEK Up?',
            placeholder: 'Short description about program. Why business partner come to GEEK Up?'
        },
        businessExpectedGoal: {
            name: 'expectedGoal',
            label: 'Expected goal',
            tooltip: "What are business partner's expected goals?",
            placeholder: "What are business partner's expected goals?"
        },
        problem: {
            name: 'problem',
            label: 'Problem',
            tooltip: 'What are business partner’s problems?',
            placeholder: 'What are business partner’s problems?'
        }
    },
    partnership: {
        label: 'Partnership',
        name: 'partnership',
        partnershipModel: {
            name: 'partnershipModel',
            label: 'Partnership model',
            options: [
                { label: 'Outsourcing', value: 'outsourcing' },
                { label: 'Product partner', value: 'product-partner' }
            ]
        },
        servicePackages: {
            name: 'servicePackages',
            label: 'Service packages',
            placeholder: 'Select or create new service packages'
        }
    },
    product: {
        label: 'Product',
        name: 'product',
        tags: {
            name: 'productTags',
            label: 'Tags',
            placeholder: 'Select or create new tags (key features, key words, ...)',
            subField: 'Choose maximum 5 tags to appear in Showcase list',
            subFieldName: 'appearTags'
        },
        productMaturity: {
            name: 'productMaturity',
            label: 'Product maturity',
            options: [
                { label: 'Prototype', value: 'prototype' },
                { label: 'MVP', value: 'MVP' },
                { label: 'Impactful', value: 'impactful' }
            ]
        },
        productExpertiseScopes: {
            name: 'expertiseScopes',
            label: 'Expertise scope involved',
            placeholder: 'Select expertise scope has involved in the program'
        },
        fileUpload: {
            name: 'fileUploads',
            label: 'File uploaded',
            messageUpload: 'Click or drag file to this area to upload',
            note: 'Please select text files (support PDF, DOC, EXL,PPT) with size no more than 50MB',
            accept: 'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf',
            apiUpload: UPLOAD.file
        },
        thumbnail: {
            name: 'thumbnail',
            label: 'Thumbnail',
            subLabel: {
                suggest: '(1 image size 40x40)',
                limitImage: '(Only have one image)'
            },
            messageUpload: 'Click or drag file to this area to upload',
            note: 'Please select 1 image (support JPEG, PNG) with size no more than 5MB',
            accept: 'image/png, image/jpeg',
            limit: 1,
            maxSize: 5, // MB
            apiUpload: UPLOAD.thumbnail
        }
    }
};

const planningPhase = {
    program: {
        label: 'Program',
        name: 'program',
        programExpectedGoal: {
            name: 'expectedGoal',
            label: 'Expected goal',
            placeholder: "What are business partner's expected goals?"
        },
        programSpecificCondition: {
            name: 'programSpecificCondition',
            label: 'Program specific condition',
            placeholder: 'What are program specific conditions?',
            tooltip: 'What are program specific conditions?'
        },
        deliverable: {
            name: 'deliverables',
            label: 'Deliverables list',
            placeholder: 'Select or create new deliverables'
        },
        timeline: {
            label: 'Timeline',
            name: 'planningTimeline',
            planningStartTime: {
                name: 'planningStartTime',
                placeholder: 'Start date'
            },
            planningEndTime: {
                name: 'planningEndTime',
                placeholder: 'End date'
            }
        },
        totalEstimationScope: { name: 'totalEstimationScope' },
        totalEstimationCost: { name: 'totalEstimationCost' },
        totalActualScope: 'totalActualScope',
        totalActualCost: 'totalActualCost',
        statistic: 'Scope/Cost statistic'
    },
    estimation: {
        label: 'Estimation',
        name: 'estimation'
    }
};

const expertiseScope = {
    label: 'Estimation',
    name: 'expertiseScopes',
    scope: {
        label: 'Scope (point)',
        plan: { name: 'estimationScope' },
        actual: { name: 'actualScope' }
    },
    cost: {
        label: 'Cost (hour)',
        plan: { name: 'estimationCost' },
        actual: { name: 'actualCost' }
    },
    complexity: {
        label: 'Complexity',
        plan: { name: 'estimationComplexity' },
        actual: { name: 'actualComplexity' }
    },
    combination: {
        label: 'Workload combination',
        plan: {
            senior: { label: 'Senior', name: 'estimationSenior' },
            middle: { label: 'Middle', name: 'estimationMiddle' },
            junior: { label: 'Junior', name: 'estimationJunior' }
        },
        actual: {
            senior: { label: 'Senior', name: 'actualSenior' },
            middle: { label: 'Middle', name: 'actualMiddle' },
            junior: { label: 'Junior', name: 'actualJunior' }
        }
    },
    solution: {
        name: 'solution',
        label: 'Solution (updated)',
        placeholder: 'What are expertise scope actual solutions?'
    },
    approach: {
        name: 'approach',
        label: 'Approach (updated)',
        placeholder: 'What are expertise scope actual approach?'
    },
    output: {
        name: 'output',
        label: 'Output',
        placeholder: 'What are expertise scope’s outputs?'
    },
    links: {
        name: 'platformLinks',
        android: {
            label: 'Link Android app',
            name: 'android',
            placeholder: 'Add Android link',
            fieldSignal: 'Link Android App'
        },
        ios: {
            label: 'Link IOS app',
            name: 'ios',
            placeholder: 'Add IOS link',
            fieldSignal: 'Link IOS app'
        },
        website: {
            label: 'Link Website link',
            name: 'website',
            placeholder: 'Add Website link',
            fieldSignal: 'Link Website'
        }
    },
    demo: {
        label: 'Demo screens (Maximum 5 images)',
        name: 'demoScreen',
        fieldSignal: 'Demo screens',
        note: 'Please select up to 5 images (support JPEG, PNG) with size no more than 5MB',
        accept: 'image/png, image/jpeg',
        limit: 5,
        apiUpload: UPLOAD.image
    }
};

const deliveryPhase = {
    label: 'Product',
    name: 'delivery'
};

const evaluatingPhase = {
    program: {
        name: 'program',
        label: 'Program',
        evaluationTimeline: {
            name: 'inputTimeline',
            label: 'Input timeline'
        }
    },
    product: {
        label: 'Product',
        name: 'product',
        expectedGoal: {
            name: 'expectedGoal',
            label: 'Expected goal'
        },
        solution: {
            name: 'solution',
            label: 'Solution (updated)',
            placeholder: 'What are business solutions to help business partner achieve goal?'
        }
    },
    businessFeedback: {
        name: 'businessFeedback',
        label: 'Business feedback',
        businessResult: {
            label: 'Business result',
            tooltip: `How is business relationship with partner? \nDoes business solution help partner achieve their goal? \nHow are business Profit & Loss?`,
            placeholder: `How is relationship between business and business partner?\nDoes business solution help business partner achieve their goal?\nHow are business Profit & Loss?`
        }
    },
    userFeedback: {
        name: 'userFeedback',
        label: 'User feedback',
        userReview: {
            label: 'User review',
            tooltip: `How do users feedback? \nDoes our solution match user need? \nWhat constraints do users meet ?`,
            placeholder: `How do users feedback?\nDoes our solution match user need?\nWhat constraints do users meet ?`
        }
    },
    techFeedback: {
        name: 'techFeedback',
        label: 'Tech feedback',
        techReview: {
            label: 'Tech review',
            tooltip: `How was technical approach? \nDoes deliverables list meet coding standard?`,
            placeholder: `How was technical approach?\nDoes deliverables list meet coding standard?`
        }
    }
};

const INITIATING_FIELDS = [
    {
        name: 'businessPartner',
        child: ['businessNature', 'context', 'expectedGoal', 'problem', 'type']
    },
    { name: 'fileUploads', child: [] },
    { name: 'partnership', child: ['partnershipModel', 'servicePackages'] },
    { name: 'product', child: ['expertiseScopes', 'productTags'] },
    { name: 'thumbnail', child: [] }
];

const PLANNING_FIELDS = [
    {
        name: 'program',
        child: ['deliverables', 'expectedGoal', 'planningTimeline', 'programSpecificCondition']
    },
    { name: 'estimation', child: [] }
];

const DELIVERY_FIELDS = [{ name: 'expertiseScopes', child: [] }];

const EVALUATING_FIELDS = [
    { name: 'product', child: ['expectedGoal', 'solution'] },
    { name: 'businessFeedback', child: [] },
    { name: 'userFeedback', child: [] },
    { name: 'techFeedback', child: [] }
];

export {
    initiatingPhase,
    planningPhase,
    expertiseScope,
    deliveryPhase,
    evaluatingPhase,
    INITIATING_FIELDS,
    PLANNING_FIELDS,
    DELIVERY_FIELDS,
    EVALUATING_FIELDS
};
