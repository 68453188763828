import * as React from "react";
const Duplicate = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.333 2.4h-8a.267.267 0 0 0-.266.267v8c0 .148.12.267.266.267h8c.148 0 .267-.12.267-.267v-8a.267.267 0 0 0-.267-.266Zm-8-1.066C4.597 1.334 4 1.931 4 2.667v8c0 .737.597 1.334 1.333 1.334h8c.737 0 1.334-.597 1.334-1.334v-8c0-.736-.597-1.333-1.334-1.333h-8Z"
      fill="#000D0B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.8 13.333V4.199a.533.533 0 0 1 1.066 0v9.134a.8.8 0 0 0 .8.8h9.467a.533.533 0 1 1 0 1.066H2.666A1.867 1.867 0 0 1 .8 13.333Z"
      fill="#000D0B"
    />
  </svg>
);
export default Duplicate;
