import React from 'react';
import { InputNumberProps, InputNumber } from '@pankod/refine-antd';

import styles from './styles.module.scss';

type EstimationInputNumberProps = InputNumberProps;

export default function EstimationInputNumber({ ...props }: EstimationInputNumberProps) {
    return (
        <InputNumber
            className={styles.inputContainer}
            bordered={false}
            controls={false}
            min={0}
            {...props}
        />
    );
}
