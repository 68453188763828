import { HttpError } from '@pankod/refine-core';
import axios from 'axios';

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const customError: HttpError = {
            ...error,
            message: error.response?.data?.message,
            statusCode: error.response?.status
        };

        return Promise.reject(customError);
    }
);

export { axiosInstance };
