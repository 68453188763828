import * as React from 'react';
const Export = (props) => (
    <svg
        width={48}
        height={48}
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.6 36a1.4 1.4 0 0 0 1.4 1.4h27a1.4 1.4 0 0 0 1.4-1.4V24a1.6 1.6 0 1 1 3.2 0v12a4.6 4.6 0 0 1-4.6 4.6H11A4.6 4.6 0 0 1 6.4 36V24a1.6 1.6 0 1 1 3.2 0v12Zm24.532-21.869a1.6 1.6 0 0 0 0-2.262l-9-9a1.6 1.6 0 0 0-2.263 0l-9 9a1.6 1.6 0 1 0 2.262 2.262L22.4 7.863V26.9a1.6 1.6 0 0 0 3.2 0V7.862l6.269 6.27a1.6 1.6 0 0 0 2.263 0Z"
            fill="#33B3AA"
        />
    </svg>
);
export default Export;
